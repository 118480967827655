import React, { useState, useCallback, useEffect } from "react";
import { Form, Space, Button, Input, Row, Col } from "antd";

import * as API from "@/util/service/service";
import * as URL from "@/util/service/url";
import { LAYOUT_COL_FOUR_ONE } from '@/util/common';
import CustomTable from '@/component/CustomTable';
import DetailModal from "./detailModal";
import { EditOutlined, SearchOutlined } from "@ant-design/icons";

function Role() {
  const [form] = Form.useForm()

  const [pageSize, setPageSize] = useState(10)
  const [pageNo, setPageNo] = useState(1)
  const [dataSource, setDataSource] = useState([])
  const [total, setTotal] = useState(0)

  const [loading, setLoading] = useState(false)

  const columns = [
    {
      title: '',
      dataIndex: 'sort',
      key: 'sort',
      render: (_text, _record) => {
        return (
          <div className='flex space-x-4 justify-center'>
            <Space>
              <DetailModal
                visibleType={'Update'}
                record={_record}
                onReset={() => (pageNo !== 1 ? setPageNo(1) : getList({ pageNo: 1, pageSize }))}
              >
                <Button
                  type="primary"
                  icon={<EditOutlined />}
                >
                </Button>
              </DetailModal>
            </Space>
          </div>
        )
      },
    },
    {
      title: '權限名稱',
      dataIndex: 'displayName',
    },
    {
      title: '權限代號',
      dataIndex: 'roleCode',
    },
  ]
  const getList = useCallback(() => {
    const { ...field } = form.getFieldsValue()
    setLoading(true)
    const params = {
      pageSize,
      pageNo,
      ...field,
    }
    API.getBodies(URL.ROLE_LIST, params).then(({ status, data: res }) => {
      if (status === 200) {
        setDataSource(res.data)
        setTotal(res.total)
      }
    }).finally(() => setLoading(false))
  }, [pageSize, pageNo,])

  useEffect(() => {
    getList({
      pageSize,
      pageNo,
    })
  }, [pageSize, pageNo])

  return <>
    <Form
      form={form}
      onFinish={() => pageNo !== 1 ? setPageNo(1) : getList({ pageNo: 1, pageSize })}>
      <Row gutter={[8, 0]}>
        <Col {...LAYOUT_COL_FOUR_ONE}>
          <Form.Item
            label="權限名稱"
            name="displayName"
          >
            <Input allowClear />
          </Form.Item>
        </Col>
        <Col {...LAYOUT_COL_FOUR_ONE}>
          <Form.Item
            label="權限代號"
            name="roleCode"
          >
            <Input allowClear />
          </Form.Item>
        </Col>
        <Col {...LAYOUT_COL_FOUR_ONE}>
          <Form.Item shouldUpdate>
            {() => (
              <Button
                type="primary"
                htmlType="submit"
                loading={loading}
                icon={<SearchOutlined />}
              >
                查詢
              </Button>
            )}
          </Form.Item>
        </Col>
      </Row>

    </Form>
    <CustomTable
      columns={columns}
      dataSource={dataSource}
      pagination={{
        pageSize,
        current: pageNo,
        total,
        onShowSizeChange: setPageSize,
        onChange: setPageNo
      }}
    />
  </>
}
export default Role