import { HashRouter, useRoutes } from "react-router-dom";
import { routeConfig } from "./config";
import { Suspense } from "react";
import { Spin } from "antd";

export default function Router() {
  const AllRoute = () => useRoutes(routeConfig);
  return (
    <HashRouter>
      <Suspense
        fallback={
          <div
            style={{
              width: "100vw",
              height: "100vh",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Spin tip="Loading..." />
          </div>
        }
      >
        <AllRoute />
      </Suspense>
    </HashRouter>
  );
}
