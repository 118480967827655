import Order from "@/page/order";
import UniformOrder from "@/page/order/add";
import ThankMessage from "@/page/order/thank/ThankMessage";
// import UploadPay from "@/page/order/uploadPay/UploadPay";

import Admin from "@/page/admin";
import Login from "@/page/admin/login";
import Manage from "@/page/admin/manage";
import User from "@/page/admin/user";
import Role from "@/page/admin/role";
import OrderManage from "@/page/admin/order";
import CustomerManage from "@/page/admin/customer";
import Announcements from "@/page/admin/announcements";


import { Navigate } from "react-router-dom";

export const routeConfig = [

  {
    path: "/order",
    element: <Order />,
    children: [
      {
        path: "add",
        exact: true,
        element: <UniformOrder />,
      },
      // {
      //   path: "uploadPay",
      //   exact: true,
      //   element: <UploadPay />,
      // },
      {
        path: "thank",
        exact: true,
        element: <ThankMessage />,
      },
    ],
  },
  {
    path: "/",
    element: <Navigate to="/order/add" />,
  },
  {
    path: "/admin",
    element: <Admin />,
    exact: true,
    children: [
      {
        index: true,
        element: <Navigate to="/admin/manage" />,
      },
      {
        path: "manage",
        element: <Manage />,
        exact: true,
        children: [
          {
            index: true,
            element: <Navigate to="/admin/manage/role-manage" />,
          },
          {
            exact: true,
            path: "role-manage",
            element: <Role />,
          },
          {
            path: "user-manage",
            exact: true,
            element: <User />,
          },
          {
            path: "order-manage",
            exact: true,
            element: <OrderManage />,
          },
          {
            path: "customer-manage",
            exact: true,
            element: <CustomerManage />,
          },
          {
            path: "announcements-manage",
            exact: true,
            element: <Announcements />,
          }
        ]
      },
      {
        path: "login",
        exact: true,
        element: <Login />,
      },
    ],
  },
];
