import axios from "./config";

export const getBodies = (url, params, config) => {
  return axios.get(url, {
    params,
    withCredentials: true,
    needLogin: true,
    ...config
  }).catch((error) => {
    return error
  });
};

export const postBodies = (url, data, config) => {
  return axios.post(url, data, {
    withCredentials: true,
    needLogin: true,
    ...config
  }).catch((error) => {
    return error
  });
};


export const postFormData = (url, data, config) => {
  return axios.post(url, data, {
    withCredentials: true,
    headers: { "Content-Type": "multipart/form-data" },
    ...config
  }).catch((error) => {
    return error
  });
};
