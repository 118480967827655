import { useCallback, useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Slider from 'react-slick'
import { Col, Divider, Form, Image, Input, Row, Select, Typography, message, Space } from 'antd'
import { ZoomInOutlined } from '@ant-design/icons'

import { getUniformParams, priceFormat, uniformSecondData } from '../constant'

import PayImage from '@/static/image2/支付二维码.png'
import { ClauseUl, DescText, ProductContent, UniformOrderFooter, UserFormItem } from '../style'

import UserInfo from './UserInfo'
import ProductCard from './ProductCard'
import ConfirmModal from './ConfirmModal'

import { SubmitButton } from '../style'

export default function UniformOrder() {
  const [form] = Form.useForm()

  const [checkedList, setCheckedList] = useState([])
  const [totalPrice, setTotalPrice] = useState(0.0)

  const [loading, setLoading] = useState(false)

  const [uniformDetail, setUniformDetail] = useState({})

  useEffect(() => {
    CountTotalPrice(checkedList)
  }, [checkedList])

  const settings = {
    infinite: true,
    slidesToShow: 1,
    speed: 500,
  }

  const handleChange = (change, allChanges) => {
    console.log(change, allChanges)
  }

  const CountTotalPrice = (checkedList) => {
    console.log(checkedList)
    const sum = checkedList
      .filter((item) => item.isCheck)
      .map((item) => item.price * item.quantity)
      .reduce((acc, cur) => acc + cur, 0)
    console.log(sum)
    setTotalPrice(sum)
  }

  const productCardCallback = (value) => {
    setCheckedList((self) => {
      let index = self.findIndex((i) => i.key === value.key)
      if (index === -1) {
        return [...self, value]
      } else {
        self[index] = value
        return [...self]
      }
    })
  }

  const handleBeforeCheck = useCallback(() => {
    return new Promise((resolve, reject) => {
      form
        .validateFields()
        .then(() => {
          if (checkedList.some((el) => el.isCheck)) {
            resolve(true)
          } else {
            message.error('請選擇該購買的服裝')
            resolve(false)
          }
        })
        .catch(() => {
          message.error('請填寫必要的信息')
          resolve(false)
        })
    })
  }, [checkedList, form])
  
  const handleSubmit = () => {
    form
      .validateFields()
      .then((values) => {
        if (checkedList.some((el) => el.isCheck)) {
          const unifomList = checkedList.slice()
          const submitParams = getUniformParams(values, SchoolOptions)
          submitParams.totalPrice = totalPrice
          submitParams.schoolUniformList = unifomList
            .filter((item) => item.isCheck)
            .map((i) => ({
              size: i.size,
              schoolUniformName: i.schoolUniformName,
              quantity: i.quantity,
              price: i.price,
            }))
          setUniformDetail(submitParams)
          setLoading(true)
        }
      })
      .catch((err) => console.log(err))
  }

  return (
    <>
      <Form
        layout='vertical'
        name='uniform'
        className='uniform_form'
        form={form}
        onValuesChange={handleChange}
        initialValues={{
          list: uniformSecondData,
        }}
        onFinish={handleSubmit}
      >
        <UserFormItem name='schoolId' label='學校' initialValue={'1'}>
          <Select options={SchoolOptions} />
        </UserFormItem>

        {window.innerWidth < 500 ? (
          <div style={{ height: '580px' }}>
            <Slider {...settings} centerMode={true}>
              {uniformSecondData.map((product) => (
                <ProductCard DataSource={product} callBackFunc={(value) => productCardCallback(value)} />
              ))}
            </Slider>
          </div>
        ) : (
          <Space style={{ width: '100%', marginBottom: '12px', flexWrap: 'wrap' }}>
            {uniformSecondData.map((product) => (
              <ProductCard
                DataSource={product}
                callBackFunc={(value) => {
                  setCheckedList((self) => {
                    let index = self.findIndex((i) => i.key === value.key)
                    if (index === -1) {
                      return [...self, value]
                    } else {
                      self[index] = value
                      return [...self]
                    }
                  })
                }}
              />
            ))}
          </Space>
        )}

        <ProductContent>
          <Divider style={{ margin: '12px' }} />
          <Row>
            <Col style={{ marginLeft: 'auto', padding: '1em 0' }}>
              <Typography.Text style={{ marginRight: '1em' }}>總計</Typography.Text>
              <DescText fontSize='14px' style={{ fontWeight: 'bold', display: 'inline-block' }}>
                ${isNaN(totalPrice) ? priceFormat((0.0).toFixed(2)) : priceFormat(totalPrice?.toFixed(2))}
              </DescText>
            </Col>
          </Row>
        </ProductContent>
        <Form.Item name='remark' label='備註'>
          <Input.TextArea rows={7} />
        </Form.Item>
        <Form.Item
          name='way'
          label='取貨方式'
          rules={[
            {
              required: true,
              message: 'Please select an option!',
            },
          ]}
        >
          <Select
            allowClear
            options={[
              {
                value: '到店取貨',
                label: '到店取貨',
              },
              {
                value: '送貨到學校',
                label: '送貨到學校',
              },
              {
                value: '其他(請填寫到備註欄)',
                label: '其他(請填寫到備註欄)',
              },
            ]}
            onChange={(e)=>{console.log('%c [ e ]-193', 'font-size:13px; background:pink; color:#bf2c9f;', e)}}
          />
        </Form.Item>
        <UserInfo form={form} />
        <Form.Item label='購買指引:'>
          <ClauseUl>
            <li>在校服照片左上角勾選需要的款式，然後依次選擇數量、尺寸，系統會自動計算總價。有特別要求請填寫備註。</li>
            <li>填寫完整的學生資料。</li>
            <li>點擊最下方的“提交”按鈕。</li>
            <li>
              提交訂單後，頁面將轉到上傳支付結果頁面，請用Mpay掃描二維碼，<span style={{ backgroundColor:"#f0ac3a" }}>在付款界面的備註中填寫學生姓名，支付成功後請截圖</span>。
            </li>
            <li>返回上傳支付結果頁，輸入您的聯絡電話及支付成功的截圖，點擊“提交”。</li>
            <li>訂單提交成功，您的郵箱將會收到訂單記錄。兩個工作日內會收到本司客服電話回訪。</li>
          </ClauseUl>
        </Form.Item>
        <Form.Item label='條款/注意事項:'>
          <ClauseUl>
            <li>
              <span style={{ display: 'block' }}>收貨後7日內可免費更換另一件不同碼數，僅限未洗水穿用過之校服。</span>
            </li>
            <li>關於訂單或支付問題、售後或其他問題，歡迎連絡68905866 鄧小姐。</li>
            <li>
              {/* <span style={{ display: "block" }}>
                如提交訂單後沒有支付成功，請繼續用以下二維碼支付訂單總金額，支付後
                <Link to="/order/uploadPay">在這裡</Link>
                上傳支付成功截圖
              </span> */}
              <Image
                alt={'支付二維碼'}
                src={PayImage}
                height={148}
                style={{ objectFit: 'contain', padding: '1px 0' }}
                preview={{ mask: <ZoomInOutlined /> }}
              />
            </li>
          </ClauseUl>
        </Form.Item>
        <UniformOrderFooter>
          <ConfirmModal uniformDetail={uniformDetail} onReset={() => setLoading(false)} beforeCheck={handleBeforeCheck}>
            <SubmitButton loading={loading} htmlType='submit'>
              提交
            </SubmitButton>
          </ConfirmModal>
        </UniformOrderFooter>
      </Form>
    </>
  )
}

const SchoolOptions = [
  { label: '澳門浸信中學', value: '1' },
  // { label: "XYZ學校", value: "2" },
]
