import React, { useState } from 'react'
import { DescText, ProductDesc } from '@/page/order/style'
import { COLORS } from '../../../../src/constant'
import { Card, Image, Space, Select, Row, Col, Checkbox, Button, Modal } from 'antd'
import ImageGroup from '@/component/Image'
import { ZoomInOutlined } from '@ant-design/icons'

const SizeChart = (props) => {
  const [imageVis, setImageVis] = useState(false)
  return (
    <div>
      <Button
        className='ProductCardButton'
        onClick={(e) => {
          e.stopPropagation()
          setImageVis(true)
        }}
      >
        尺寸表
      </Button>
      <Image
        onClick={(e) => e.stopPropagation()}
        preview={{
          visible: imageVis,
          src: props.src,
          onVisibleChange: (value) => {
            setImageVis(value)
          },
        }}
      />
    </div>
  )
}

const ProductList = ({ DataSource, callBackFunc, productIntro }) => {
  const init = React.useRef(false)
  const product = DataSource
  const [isCheck, setIsCheck] = React.useState(false)
  const [price, setPrice] = React.useState(product.sizeOption[0].price)
  const [size, setSize] = React.useState(product.sizeOption[0].sizeName)
  const [quantity, setQuantity] = React.useState(1)

  React.useEffect(() => {
    if (init.current) {
      callBackFunc({
        key: product.key,
        schoolUniformName: product.name,
        size: size,
        quantity: quantity,
        price: price,
        isCheck: isCheck,
      })
    } else {
      init.current = true
      setPrice(product.sizeOption[0].price)
    }
  }, [isCheck, price, size, quantity])
  return (
    <Card
      className='ProductCard'
      bordered
      bodyStyle={{ padding: '10px' }}
      style={{ width: 215, minHeight: '545px' }}
      cover={
        <>
          <Checkbox
            checked={isCheck}
            className='ProductChecker'
            style={{ width: '20px', height: '20px' }}
            onChange={() => setIsCheck((self) => !self)}
          />
          {product.imgData.length === 0 ? (
            <Image
              alt={product.name}
              src={product.picture}
              style={{ objectFit: 'contain', padding: '1px 0', height: 'auto' }}
              preview={{ mask: <ZoomInOutlined /> }}
            />
          ) : (
            <ImageGroup title={product.name} img={product.picture} imgData={product.imgData} />
          )}
        </>
      }
    >
      <Card.Meta
        // onClick={() => setIsCheck((self) => !self)}
        style={{ textAlign: 'left' }}
        className='ProductCard'
        title={
          <div className='ProductCardTitle' style={{ zIndex: 10, position: 'relative' }}>
            <div style={{ width: '110px' }}>
              <span className='ProductName'>{product.name}</span>
            </div>
            <div className='ProductCardButtonGroup'>
              <div>{DataSource.sizeChart && <SizeChart src={DataSource.sizeChart} />}</div>
              <Button
                className='ProductCardButton'
                style={{ display: DataSource.productIntro ? '' : 'none' }}
                onClick={() => {
                  Modal.info({
                    title: '產品介紹',
                    content: (
                      <p>
                        {DataSource.productIntro}
                        {DataSource.descriptionTag && <Image preview={false} src={DataSource.descriptionTag} />}
                      </p>
                    ),
                  })
                }}
              >
                產品介紹
              </Button>
            </div>
          </div>
        }
        description={
          <>
            <ProductDesc>
              <DescText fontSize='14px' style={{ opacity: '0.6' }}>
                {product.uniformId}
              </DescText>
              <DescText
                fontSize='12px'
                style={{
                  opacity: '0.6',
                  marginTop: '8px',
                  marginBottom: '8px',
                  minHeight: '100px',
                }}
              >
                {product.description}
              </DescText>
              <DescText fontSize='14px' color={COLORS.uniform_price} style={{ fontWeight: 'bold' }}>
                MOP {price}
              </DescText>
              {product.content}
            </ProductDesc>
            <Space style={{ gap: '10px', marginTop: '10px' }}>
              <Row>
                <Col span={24} style={{ fontSize: '1rem', color: 'black' }}>
                  數量
                </Col>
                <Col span={24}>
                  <Select
                    defaultValue={1}
                    onClick={(e) => e.stopPropagation()}
                    onChange={(value) => setQuantity(value)}
                    style={{ width: '100%' }}
                    options={[...Array(10)].map((_, i) => ({ value: i + 1 }))}
                  />
                </Col>
              </Row>
              <Row style={{ width: '100px' }}>
                <Col span={24} style={{ fontSize: '1rem', color: 'black' }}>
                  尺寸
                </Col>
                <Col span={24}>
                  <Select
                    defaultValue={product.sizeOption[0].sizeName}
                    value={size}
                    onClick={(e) => e.stopPropagation()}
                    onChange={(value, option) => {
                      setSize(value)
                      setPrice(option.price)
                    }}
                    style={{ width: '100%' }}
                    fieldNames={{ label: 'sizeName', value: 'sizeName' }}
                    options={product.sizeOption}
                  />
                </Col>
              </Row>
            </Space>
          </>
        }
      />
    </Card>
  )
}

export default ProductList
