import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Layout, Menu, Button, theme, message, Avatar, Dropdown } from "antd";
import { Outlet, useNavigate } from "react-router-dom";
import { useSize } from 'ahooks'

import "./index.css";
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
} from '@ant-design/icons';

import { clearSessionStorage } from '@/util/common.js'

const { Header, Content } = Layout;

const MenuItems = [
  {
    key: '/admin/manage/user-manage',
    label: '用戶管理',
  },
  {
    key: '/admin/manage/role-manage',
    label: '權限管理',
  },
  {
    key: '/admin/manage/order-manage',
    label: '訂單管理',
  },
  {
    key: '/admin/manage/customer-manage',
    label: '客戶管理',
  },
  {
    key: '/admin/manage/announcements-manage',
    label: '通告管理',
  }
]

function Manage() {
  const UserData = sessionStorage.getItem('UserData')
  const showUserName = useMemo(() => {
    const UserInfor = JSON.parse(UserData)
    console.log('%c [ UserInfor ]-34', 'font-size:13px; background:pink; color:#bf2c9f;', UserInfor)
    return UserInfor?.displayName ?? null
  }, [UserData])

  const layoutRef = useRef()
  const customSize = useSize(layoutRef)

  const [selectedKeys, setSelectedKeys] = useState([])

  useEffect(() => {
    MenuItems.forEach(el => {
      if (window.location.href.includes(el.key)) setSelectedKeys([el.key])
    })
  }, [])
  
  const navigate = useNavigate();

  const handleOnSelect = useCallback(({ key, selectedKeys, }) => {
    navigate(key)
    setSelectedKeys(selectedKeys)
  }, [navigate])

  const items = [
    {
      key: '1',
      label: <span className='text-base ml-2'>{showUserName}</span>,
    },
    {
      key: '2',
      danger: true,
      label: <span className='text-base ml-2'>Sign Out</span>,
    },
  ]
  const handleSignOut = useCallback(() => {
    clearSessionStorage()
    navigate('/admin')
    window.location.reload()
  }, [navigate])

  const onClick = useCallback(({ key }) => {
    switch (key) {
      case '2':
        handleSignOut()
        break
      default:
        break
    }
  }, [handleSignOut])

  const [collapsed, setCollapsed] = useState(false);
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  const loginStatus = isLogin(() => {
    message.error('Login failed, three seconds to go to the login page')
    setTimeout(() => {
      navigate('/admin/login')
    }, 3000)
  })

  if (loginStatus) {
    return (
      <Layout>
        <Header
          style={{
            position: 'sticky',
            top: 0,
            zIndex: 1,
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            background: colorBgContainer,
          }}
          ref={layoutRef}
        >
          <div className="demo-logo" />
          <Menu
            theme="light"
            mode="horizontal"
            items={MenuItems}
            style={{
              flex: 1,
              minWidth: 0,
              border: 0,
            }}
            selectedKeys={selectedKeys}
            onSelect={handleOnSelect}
          />
          <Button
            className="Collapsed-Btn"
            type="text"
            icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
            onClick={() => setCollapsed(!collapsed)}
            style={{
              fontSize: '16px',
              width: 64,
              height: 64,
            }}
          />
          <Dropdown
            menu={{
              items,
              onClick,
            }}
          >
            <Avatar style={{ backgroundColor: '#7265e6', verticalAlign: 'middle' }} size="large" title={showUserName}>
              {showUserName}
            </Avatar>
          </Dropdown>

        </Header>
        <Content
          style={{
            margin: customSize?.width >= 768 ? '24px 16px' : '0',
          }}
        >
          <div
            className="box-border"
            style={{
              padding: 24,
              minHeight: customSize?.width >= 768 ? 'calc(100vh - 112px)' : 'calc(100vh - 64px)',
              background: colorBgContainer,
              borderRadius: borderRadiusLG,
            }}
          >
            <Outlet />
          </div>
        </Content>

      </Layout>
    );
  } else {
    return <></>
  }
}
export default Manage

const isLogin = function (goLogin) {
  const token = sessionStorage.getItem('token')
  if (!token) {
    goLogin()
    return (false)
  } else {
    return (true)
  }
}

// const checkLogin = function (goLogin) {
//   return new Promise((resolve, reject) => {
//     const token = sessionStorage.getItem('token')
//     if (!token) {
//       goLogin()
//       reject(false)
//     } else {
//       resolve(true)
//     }
//   })
// }
