import React, { useState, useEffect } from 'react'

const getAnnouncements = () => {
  return new Promise((resolve, reject) => {
    fetch('/api/announcManage/Announcements', { method: 'GET' })
      .then((res) => res.json())
      .then((res) => {
        resolve(res)
      })
      .catch(() => reject())
  })
}

const AnnouncementCarousel = () => {
  const [currentIndex, setCurrentIndex] = useState(0)
  const [Announcements, setAnnouncements] = useState([])

  useEffect(() => {
    if (Announcements.length <= 0) {
      getAnnouncements()
        .then((data) => setAnnouncements(data))
        .catch(() => setAnnouncements([]))
    }
  }, [])

  useEffect(() => {
    if (Announcements.length > 0) {
      const maxIndex = document.getElementById('AnnouncementsContainer').offsetHeight / 36
      const interval = setInterval(() => {
        setCurrentIndex((self) => (self === maxIndex - 1 ? 0 : self + 1))
      }, 5000)
      return () => clearInterval(interval)
    }
  }, [Announcements])

  return (
    <>
      {Announcements.length > 0 ? (
        <div
          style={{
            height: '36px',
            overflow: 'hidden',
            position: 'relative',
            borderRadius: '4px',
            color: 'red',
            marginTop: '36px',
            marginLeft: '12px',
            marginRight: '12px',
            background: 'lightgray',
          }}
        >
          <div
            id='AnnouncementsContainer'
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              flexDirection: 'column',
              textAlign: 'center',
              position: 'relative',
              top: `${currentIndex * -36}px`,
              transition: 'top 0.5s ease',
            }}
          >
            {Announcements.map((announcement, index) => (
              <div
                style={{
                  flex: '0 0 100%',
                  lineHeight: '36px',
                }}
                key={index}
              >
                {announcement}
              </div>
            ))}
          </div>
        </div>
      ) : (
        ''
      )}
    </>
  )
}

export default AnnouncementCarousel
