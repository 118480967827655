import styled from "styled-components";
import { COLORS } from "../../constant";
import { Button, Form, Row, Space } from "antd";

const ProductContain = styled.div`
  max-width: 768px;
  margin: 0 auto;
`;
const ProductDesc = styled.div``;
const CardTitle = styled.div`
  text-align: center;
  padding: 2em 0;
  white-space: pre-line;
  @media only screen and (max-width: 485px) {
    font-size: 12px;
    padding: 1em 0;
  }
`;
const DescText = styled.span`
  display: block;
  color: ${(props) => (props.color ? props.color : COLORS.uniform_description)};
  font-size: ${(props) => (props.fontSize ? props.fontSize : "1rem")};
  white-space: pre-line;
  word-break: break-word;
  font-weight: ${(props) => (props.bold ? props.bold : "normal")};
`;

const ProductContent = styled.div``;

const ProductText = styled.h1`
  margin: 0;
`;

const Title2 = styled.h2`
  margin: 0;
  padding: 0 0.5em;
`;

const CardCover = styled.div`
  height: 148px;
`;

const FormRowItem = styled(Row)`
  margin-top: 10px;
  .ant-form-item {
    margin-bottom: 0;
    width: 4rem;
  }
`;

const MyFormItem = styled(Form.Item)`
  margin-right: 10px;
  .ant-form-item-label > label {
    font-size: 0.75em;
  }
`;

const CheckFormCard = styled(Form.Item)`
  position: absolute;
  top: 12px;
  left: 10px;
  z-index: 100;
  font-size: 1rem;
  margin-bottom: 0;
  & {
    .ant-checkbox .ant-checkbox-inner {
      width: 20px;
      height: 20px;
    }
  }
`;

const UserFormItem = styled(Form.Item)`
  width: calc(50% - 0.75em);
  @media only screen and (max-width: 485px) {
    /* For mobile phones: */
    width: 100%;
  }
`;

const UniformOrderFooter = styled.div`
  background-color: #fff;
  border-top: 1px solid var(--borderColor);
  border-radius: 0 0 1em 1em;
  padding: 2em 0.5em;
  text-align: center;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.03),
    0 1px 6px -1px rgba(0, 0, 0, 0.02), 0 2px 4px 0 rgba(0, 0, 0, 0.02);
`;

const SubmitButton = styled(Button)`
  background-color: var(--uniform_base);
  padding: 0.75em 5em;
  height: auto;
  color: #fff;
  font-size: 1em;
  @media only screen and (max-width: 485px) {
    padding: 0.25em 0.9375em;
    width: 100%;
  }
`;

const UserSpace = styled(Space)`
  flex-direction: column;
  align-items: flex-start;
  width: calc(50% - 0.75em);
  & > .ant-space-item:first-child {
    width: 100%;
  }
  .ant-form-item {
    margin-bottom: 0;
  }
  @media only screen and (max-width: 485px) {
    &.email_space {
      width: 100%;
    }
  }
`;

const ClauseUl = styled.ul`
  & > li {
    font-size: 0.9375em;
  }
`;

const ErrorMessage = styled.span`
  color: #ff4d4f;
`;

const Banner = styled.div`
  background: ${(props) =>
    `url(${
      props?.url ? props.url : "@/static/image2/cw_banner.jpg"
    }) no-repeat`};
  background-origin: content-box;
  background-size: cover;
  width: 100%;
  height: 88px;
  @media only screen and (max-width: 485px) {
    height: 41px;
  }
`;

export {
  ProductContain,
  ProductDesc,
  CardTitle,
  ProductContent,
  DescText,
  ProductText,
  Title2,
  CardCover,
  FormRowItem,
  MyFormItem,
  UserFormItem,
  CheckFormCard,
  UniformOrderFooter,
  SubmitButton,
  UserSpace,
  ClauseUl,
  ErrorMessage,
  Banner,
};
