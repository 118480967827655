import React, { useState, useEffect, useCallback } from 'react'
import { useNavigate } from 'react-router-dom';

import { Form, Table, message } from 'antd'



import * as API from "@/util/service/service";
import * as URL from "@/util/service/url";

import UploadImage from "@/component/Image/Upload";
import CustomModal from '@/component/CustomModal'

import { DescText } from "../../style";
import { getPrice } from "../../constant";

function ConfirmModal({ children, visibleType, onReset, beforeCheck, uniformDetail }) {
  const navigate = useNavigate();

  const [form] = Form.useForm()

  const [visible, setVisible] = useState(false)

  const childrenWithProps = React.Children?.map(children, (child) =>
    React.cloneElement(child, {
      onClick: async () => {
        let bool = true
        beforeCheck && (bool = await beforeCheck())
        bool && setVisible(true)
      }
    })
  )

  const handleSave = useCallback(() => {
    return form
      .validateFields()
      .then((vals) => {
        console.log('%c [ uniformDetail ]-35', 'font-size:13px; background:pink; color:#bf2c9f;', uniformDetail)
        const formData = new FormData();
        formData.append("schoolName", uniformDetail["schoolName"]);
        formData.append("schoolClass", uniformDetail["schoolClass"]);
        formData.append("studentFirstName", uniformDetail["studentFirstName"]);
        formData.append("studentLastName", uniformDetail["studentLastName"]);
        formData.append("studentNumber", uniformDetail["studentNumber"]);
        uniformDetail?.studentEmail && formData.append("studentEmail", uniformDetail["studentEmail"]);
        formData.append("studentPhone", uniformDetail["studentPhone"]);
        uniformDetail?.remark && formData.append("remark", uniformDetail["remark"]);
        formData.append("way", uniformDetail["way"]);
        formData.append("totalPrice", uniformDetail["totalPrice"]);
        uniformDetail["schoolUniformList"].forEach((el, index) => {
          formData.append(`schoolUniformList[${index}].schoolUniformName`, el.schoolUniformName);
          formData.append(`schoolUniformList[${index}].quantity`, el.quantity);
          formData.append(`schoolUniformList[${index}].size`, el.size);
          formData.append(`schoolUniformList[${index}].price`, el.price);

        })
        formData.append("file", vals["imageUpload"]?.[0]?.originFileObj);

        return API.postFormData(URL.CLIENT_SUBMIT_ORDER, formData).then(({ status, data: res }) => {
          console.log('%c [ formData ]-59', 'font-size:13px; background:pink; color:#bf2c9f;', formData)
          if (status === 200) {
            form.resetFields()
            message.success('落單成功')
            onReset()
            setVisible(false)
            navigate("/order/thank");
          }
        })
      }).catch(err => console.log(err))
  }, [form, navigate, onReset, uniformDetail])

  const handleOk = () => {
    return handleSave()
  }

  const handleCancel = () => {
    setVisible(false)
    onReset()
  }

  useEffect(() => {
    return () => { form.resetFields() }
  }, [form])


  return (
    <>
      {childrenWithProps}
      <CustomModal
        visibleType={visibleType}
        title="訂單內容"
        open={visible}
        onCancel={handleCancel}
        onOk={handleOk}
      >
        {uniformDetail?.schoolUniformList?.length > 0 && (
          <>
            <Table
              bordered
              size="small"
              columns={OrderColumns}
              dataSource={uniformDetail?.schoolUniformList}
              pagination={false}
              style={{ marginBottom: "1rem" }}
            />
            <DescText>
              總價：{getPrice(uniformDetail?.schoolUniformList).toFixed(2)}
            </DescText>
          </>
        )}
        <DescText>備注：{uniformDetail?.remark}</DescText>
        <DescText>取貨方式：{uniformDetail?.way}</DescText>
        <DescText bold="bold">客戶資料：</DescText>
        <DescText>學校：{uniformDetail?.schoolName}</DescText>
        <DescText>
          學生姓名：{uniformDetail?.studentLastName}{uniformDetail?.studentFirstName}
        </DescText>
        <DescText>班別：{uniformDetail?.schoolClass}</DescText>
        <DescText>學號：{uniformDetail?.studentNumber}</DescText>
        <DescText>聯絡電話：{uniformDetail?.studentPhone}</DescText>
        <DescText>電郵：{uniformDetail?.studentEmail}</DescText>
        {/* <Image
            alt={"支付二維碼"}
            src={PayImage}
            height={50}
            style={{ objectFit: "contain", padding: "1px 0" }}
            preview={{ mask: <ZoomInOutlined /> }}
          /> */}
        <Form form={form} className="pay_form">
          <Form.Item required label="上傳支付成功截圖">
            <UploadImage required={true} />
          </Form.Item>
        </Form>
      </CustomModal>
    </>
  )
}

export default ConfirmModal


const OrderColumns = [
  {
    title: "校服",
    dataIndex: "schoolUniformName",
    key: "schoolUniformName",
  },
  {
    title: "尺寸",
    dataIndex: "size",
    key: "size",
  },
  {
    title: "數量",
    dataIndex: "quantity",
    key: "quantity",
  },
  {
    title: "單價",
    dataIndex: "price",
    key: "price",
    render: (value) => <span>{value?.toFixed(2)}</span>,
  },
];
