import React, { useState, useEffect, useRef, useCallback } from 'react'
import { Form, Input, message } from 'antd'

import * as API from "@/util/service/service";
import * as URL from "@/util/service/url";

import CustomModal from '@/component/CustomModal'
import FormTransfer from '@/component/FormTransfer'

function DetailModal({ children, visibleType, onReset, record }) {

  const [form] = Form.useForm()

  const modalRef = useRef()
  const [visible, setVisible] = useState(false)

  const [orginUser, setOrginUser] = useState([])


  const getOpts = useCallback(() => {
    orginUser.length <= 0 && API.getBodies(URL.ORDER_USER_LIST).then(({ status, data: res }) => {
      if (status === 200) {
        setOrginUser(res.map(m => ({
          key: m.key,
          title: m.val,
        })))
      }
    })
  }, [orginUser.length])

  const childrenWithProps = React.Children?.map(children, (child) =>
    React.cloneElement(child, {
      onClick: () => {
        setVisible(true)
      },
    })
  )

  const handleSave = useCallback(() => {
    return form
      .validateFields()
      .then((vals) => {
        return API.postBodies(URL.EDIT_ROLE, { ...vals }).then(({ status }) => {
          if (status === 200) {
            message.success('保存成功')
            onReset()
            setVisible(false)
          }
        })
      }).catch(err => console.log(err))
  }, [form, onReset])

  const handleOk = () => {
    return handleSave()
  }

  const handleCancel = () => {
    setVisible(false)
  }

  const getDetail = useCallback(() => {
    API.getBodies(`${URL.ROLE_DETAIL}/${record.roleCode}`,).then(({ status, data: res }) => {
      if (status === 200) {
        const { userList, ...other } = res
        form.setFieldsValue({
          ...other,
          userIdList: userList.map(el => el.key)
        })
      }
    })
  }, [form, record])

  useEffect(() => {
    getOpts()
  }, [getOpts])

  useEffect(() => {
    visible && visibleType === 'Update' && record && getDetail()
    return () => { form.resetFields() }
  }, [record, visible, visibleType])


  return (
    <>
      {childrenWithProps}
      <CustomModal
        visibleType={visibleType}
        title={`${visibleType === 'Create' ? '新增' : '修改'}權限`}
        open={visible}
        onCancel={handleCancel}
        onOk={handleOk}
      >
        <div ref={modalRef} className='w-full'></div>
        <Form
          // layout={customSize?.width <= 768 ? 'vertical' : 'horizontal'}
          form={form}
          colon={false}
        >
          <Form.Item
            label='權限名稱'
            name='displayName'
            rules={[{ required: true, message: 'Please Input!' }]}
          >
            <Input allowClear />
          </Form.Item>
          <Form.Item
            label='權限代號'
            name='roleCode'
            rules={[{ required: true, message: 'Please Input!' }]}
          >
            <Input allowClear />
          </Form.Item>
          <Form.Item
            label='用戶列表'
            name='userIdList'
            rules={[{ required: true, message: 'Please select!' }]}
          >
            <FormTransfer
            titles={['所有用戶', '授權用戶']}
              dataSource={orginUser}
            />
          </Form.Item>
        </Form>
      </CustomModal>
    </>
  )
}

export default DetailModal
