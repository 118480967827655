import React, { useMemo, useRef } from 'react';
import { Table } from 'antd';
import { useSize } from 'ahooks'

export default function CustomTable({ pagination, getData, columns, dataSource, ...props }) {

  const tableRef = useRef()
  const customSize = useSize(tableRef)

  const initColumns = useMemo(() => {
    return columns.map((el) => ({ align: 'center', className: 'min-w-100', ...el }))
  }, [columns])

  const initDataSource = useMemo(() => {
    return props?.rowKey ? dataSource : dataSource.map((el, index) => ({ key: index, ...el }))
  }, [dataSource, props?.rowKey])

  return (<Table
    ref={tableRef}
    bordered={true}
    columns={initColumns}
    dataSource={initDataSource}
    size={customSize?.width >= 768 ? 'large' : 'small'}
    {...props}
    pagination={{
      showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
      ...pagination
    }}
    scroll={{
      x: '100%'
    }}
  >
  </Table>)
}