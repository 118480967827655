import React, { useState } from "react";
import { Divider, Form, Input } from "antd";
import {
  DescText,
  ErrorMessage,
  Title2,
  UserFormItem,
  UserSpace,
} from "../style";

const requiredRules = {
  required: true,
  message: "This field is required.",
};

export default function UserInfo({ form }) {
  const [showRequire, setShowRequire] = useState([false, false]);

  const handleUp = (e) => {
    form &&
      form.setFieldValue("studentPhone", e.target.value.replace(/\D/g, ""));
  };

  return (
    <div>
      <Title2>客戶資料</Title2>
      <Divider />
      <Form.Item label="學生名字" required>
        <div style={{ width: "100%" }}>
          <UserSpace>
            <Form.Item
              name="studentLastName"
              rules={[
                {
                  required: true,
                  message: "",
                },
                {
                  validator: async (rule, value) => {
                    setShowRequire([showRequire[0], !value]);
                    if (!value) {
                      throw new Error("");
                    }
                  },
                },
              ]}
            >
              <Input />
            </Form.Item>
            <DescText fontSize=".75em">姓</DescText>
          </UserSpace>
          <UserSpace style={{ marginRight: "1.5em" }}>
            <Form.Item
              name="studentFirstName"
              rules={[
                {
                  required: true,
                  message: "",
                },
                {
                  validator: async (rule, value) => {
                    setShowRequire([!value, showRequire[1]]);
                    if (!value) {
                      throw new Error("");
                    }
                  },
                },
              ]}
            >
              <Input />
            </Form.Item>
            <DescText fontSize=".75em">名</DescText>
          </UserSpace>
          {showRequire.includes(true) && (
            <ErrorMessage>This field is required.</ErrorMessage>
          )}
        </div>
      </Form.Item>
      <UserFormItem name="schoolClass" label="班別" rules={[requiredRules]}>
        <Input />
      </UserFormItem>
      <UserFormItem name="studentNumber" label="學號" rules={[requiredRules]}>
        <Input />
      </UserFormItem>
      <UserFormItem
        name="studentPhone"
        label="連絡電話"
        rules={[
          requiredRules,
          { pattern: /^\d{8}$/, message: "Field value must fill mask." },
        ]}
      >
        <Input
          type="tel"
          onKeyUp={handleUp}
          onKeyDown={handleUp}
          maxLength={8}
        />
      </UserFormItem>
      <Form.Item label="電郵">
        <UserSpace className="email_space">
          <Form.Item
            name="studentEmail"
            rules={[
              {
                type: "email",
                message: "Enter a valid e-mail address",
              },
              // requiredRules,
            ]}
          >
            <Input type="email" />
          </Form.Item>
          <DescText fontSize=".75em">example@example.com</DescText>
          <DescText fontSize=".75em">建議輸入，訂單會電郵給閣下</DescText>
        </UserSpace>
      </Form.Item>
    </div>
  );
}
