export function clearSessionStorage() {
  sessionStorage.removeItem('token')
  sessionStorage.removeItem('UserData')
  sessionStorage.removeItem('expiration')
}

export const LAYOUT_COL_FOUR_ONE = {
  xs: 24,
  md: 8,
  lg: 6
}

export const ONE_HALF = {
  xs: 24,
  md: 12,
  lg: 12
}

export const WHOLE_LINE = {
  xs: 24,
  md: 24,
  lg: 24
}

export const downloadFile = (res) => {
  const blob = new Blob([res.data], { type: res.headers['content-type'] })

  let temp = res.headers['content-disposition'].split(';')[1].split(`filename=`)[1]

  const link = document.createElement('a') // 创建a标签
  const objectUrl = URL.createObjectURL(blob)
  link.href = objectUrl
  link.download = temp
  link.click()
  URL.revokeObjectURL(objectUrl)
}


/* base64下載 */
export const downloadBase64 = (base64URL, fileName) => {
  console.log('%c [ base64URL, fileName ]', 'font-size:13px; background:pink; color:#bf2c9f;', base64URL, fileName)
  saveByteArray(fileName, base64ToArrayBuffer(base64URL))
}

export const saveByteArray = (reportName, byte) => {
  const link = document.createElement('a') // 创建a标签
  const blob = new Blob([byte], {
    type: 'application/vnd.ms-excel;charset=utf-8',
  })
  const objectUrl = URL.createObjectURL(blob)
  link.href = objectUrl
  link.download = reportName
  link.click()
  URL.revokeObjectURL(objectUrl)
}

// base64 transfer
export const base64ToArrayBuffer = (base64) => {
  var binaryString = window.atob(base64)
  var binaryLen = binaryString.length
  var bytes = new Uint8Array(binaryLen)
  for (var i = 0; i < binaryLen; i++) {
    var ascii = binaryString.charCodeAt(i)
    bytes[i] = ascii
  }
  return bytes
}