import { Button, Modal, Space } from 'antd'
import React, { useState } from 'react'

function CustomModal({ children, visibleType = '', title, open, onCancel, defWidth = 800, onOk, ...rest }) {
  const [loading, setLoading] = useState(false)
  const handleOnOk = () => {
    if (!loading) {
      setLoading(true)
      onOk().finally(() => {
        console.log('%c [ finally ]-13', 'font-size:13px; background:pink; color:#bf2c9f;')
        setLoading(false)
      })
    }
  }
  return (
    open && (
      <Modal
        width={defWidth}
        title={title}
        open={open}
        mask={false}
        maskClosable={false}
        onCancel={onCancel}
        // bodyStyle={{ padding: `1rem 3rem` }}
        footer={
          <Space className='flex justify-center items-center'>
            {visibleType !== 'Enquire' ? (
              <>
                <Button
                  type="primary"
                  onClick={handleOnOk}
                  loading={loading}
                >
                  確認
                </Button>
                <Button onClick={onCancel} disabled={loading}>
                  取消
                </Button>
              </>
            ) : (
              <Button onClick={onCancel} block>
                返回
              </Button>
            )}
          </Space>
        }
        centered
        forceRender
        className={`${rest?.className ?? ''}`}
        {...rest}
      >
        {children}
      </Modal>
    )
  )
}

export default CustomModal
