import React, { useState, useEffect, useRef, useCallback } from 'react'
import {Table } from 'antd'

import * as API from "@/util/service/service";
import * as URL from "@/util/service/url";
import CustomTable from '@/component/CustomTable';

import CustomModal from '@/component/CustomModal'
function OrderListModal({ children, visibleType, onReset, record }) {

  const [pageSize, setPageSize] = useState(10)
  const [pageNo, setPageNo] = useState(1)
  const [orderDataSource, setOrderDataSource] = useState([])
  const [total, setTotal] = useState(0)

  const modalRef = useRef()
  const [visible, setVisible] = useState(false)
  const [field, setField] = useState({phone: record.phone})

  const childrenWithProps = React.Children?.map(children, (child) =>
    React.cloneElement(child, {
      onClick: () => {
        setVisible(true)
      },
    })
  )

  const handleCancel = () => {
    setOrderDataSource([]);
    setVisible(false)
  }

  const columns = [
    {
      title: '訂單ID',
      dataIndex: 'orderGroupId',
    },
    {
      title: '下單日期',
      dataIndex: 'createdAt',
    },
    {
      title: '學校',
      dataIndex: 'schoolName',
    },
    {
      title: '學生姓名',
      dataIndex: 'studentUserName',
    },
    {
      title: '班別',
      dataIndex: 'schoolClass',
    },
    {
      title: '學號',
      dataIndex: 'studentNumber',
    },
    {
      title: '連絡電話',
      dataIndex: 'studentPhone',
    },
    {
      title: '電郵',
      dataIndex: 'studentEmail',
    },
    {
      title: '總價',
      dataIndex: 'totalPrice',
    },
    {
      title: '狀態',
      dataIndex: 'status',
    },
    {
      title: '備註',
      dataIndex: 'remark',
    },
  ]

  const expandedRowRender = (rec) => {
    const columns = [
      {
        title: '校服',
        dataIndex: 'schoolUniformName',
        key: 'schoolUniformName',
      },
      {
        title: '尺寸',
        dataIndex: 'size',
        key: 'size',
      },
      {
        title: '數量',
        dataIndex: 'quantity',
        key: 'quantity',
      },
      {
        title: '單價',
        dataIndex: 'price',
        key: 'price',
      }
    ]
    const dataSource = rec.schoolUniformList.map((el, index) => ({
      key: rec.orderGroupId + index,
      ...el
    }))
    return <Table bordered size="small" columns={columns} dataSource={dataSource} pagination={false} />;
  }

  const getList = useCallback(() => {
    const params = {
      pageSize,
      pageNo,
      ...field,
    }
    API.getBodies(URL.ORDER_LIST, params).then(({ status, data: res }) => {
      if (status === 200) {
        setOrderDataSource(res.data)
        setTotal(res.total)
      }
    }).finally(() => {})
  }, [pageSize, pageNo])


  useEffect(() => {
    visible && getList({pageSize, pageNo,})
    return () => {}
  }, [getList, pageSize, pageNo, visible])


  return (
    <>
      {childrenWithProps}
      <CustomModal
        visibleType={visibleType}
        title={`用戶訂單`}
        open={visible}
        onCancel={handleCancel}
        defWidth={1400}
      >
        <div ref={modalRef} className='w-full'></div>
        <CustomTable
          columns={columns}
          dataSource={orderDataSource}
          pagination={{
            pageSize,
            current: pageNo,
            total,
            onShowSizeChange: setPageSize,
            onChange: setPageNo
          }}
          expandable={{
            expandedRowRender
          }}
        />
      </CustomModal>
    </>
  )
}

export default OrderListModal
