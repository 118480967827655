import React from "react";
import { DescText, ProductContain, SubmitButton } from "../style";
import { useNavigate } from "react-router-dom";

const THANK_MESSAGE = "感謝，我們已收到您的訂單資料。";

export default function ThankMessage() {
  const navigate = useNavigate();

  const goBack = () => {
    navigate("/");
  };

  return (
    <ProductContain style={{ padding: "0 1em", marginTop: "1rem" }}>
      <div style={{ textAlign: "center", height: "12.5em" }}>
        <DescText fontSize="2em" style={{ margin: "1em 0" }}>
          {THANK_MESSAGE}
        </DescText>

        <SubmitButton onClick={goBack}>返回</SubmitButton>
      </div>
    </ProductContain>
  );
}
