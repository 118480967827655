import React, { useState } from 'react'
import { Card, Button, Image } from 'antd'
import { Outlet } from 'react-router-dom'

import { Banner, CardTitle, DescText, ProductContain, ProductText } from './style'
import CWImage from '@/static/image2/cw_banner.jpg'
import map from '@/static/image2/map.jpg'
import mpay from '@/static/image2/mpay.png'
import reportPDF from '@/static/image2/檢測報告.pdf'
import AnnouncementCarousel from '@/component/Announcements'

export default function Order() {
  const [mapVis, setMapVis] = useState(false)
  const [mPayVis, setMPaypVis] = useState(false)
  return (
    <>
      <ProductContain>
        <div>
          <Banner url={CWImage} />
          <div className='HeaderButtonGroup'>
            <Button className='HeaderButton' onClick={() => setMapVis(true)}>
              店鋪地圖指引
            </Button>
            <Button className='HeaderButton' onClick={() => setMPaypVis(true)}>
              快速付款
            </Button>
            <Button
              className='HeaderButton'
              style={{ color: 'yellow' }}
              onClick={() => {
                const windows = window.open('', '', 'width=' + 1024 + ', height=' + 768)                
                window.open(reportPDF)
              }}
            >
              布料檢測報告
            </Button>
            <Image
              style={{ display: 'none' }}
              preview={{ visible: mapVis, src: map, onVisibleChange: (value) => setMapVis(value) }}
            />
            <Image
              style={{ display: 'none' }}
              preview={{ visible: mPayVis, src: mpay, onVisibleChange: (value) => setMPaypVis(value) }}
            />
          </div>
        </div>
        <AnnouncementCarousel />
        <Card
          title={
            <CardTitle>
              <ProductText>CW Concept</ProductText>
              <DescText style={{ fontWeight: 'normal' }}>校服訂單</DescText>
            </CardTitle>
          }
          bordered={false}
          className='uniform_card'
        >
          <Outlet />
        </Card>
      </ProductContain>
    </>
  )
}
