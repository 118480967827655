import { message } from "antd";
import axios from "axios";
import dayjs from "dayjs";

import { clearSessionStorage } from '@/util/common.js'

// const instance = axios.create();
// instance.interceptors.request.use((config) => {
//   config.headers = { "accept-language": "en" };
//   return config;
// });

const axiosInstance = axios.create({
  baseURL: '',
  timeout: 60000,
  headers: {
    'Content-Type': 'application/json',
  },
})

axiosInstance.interceptors.request.use(
  async (config = {}) => {
    let { headers = {}, url, needLogin = false, ...rest } = config

    if (needLogin) {
      const expiration = sessionStorage.getItem('expiration')
      if (((!expiration && !url.includes('login')) || dayjs().isAfter(dayjs(expiration)))) {
        clearSessionStorage()
        setTimeout(() => {
          window.location.reload()
        }, 2000)
      }
      const token = sessionStorage.getItem('token')
      token && (headers.Authorization = token)
    }
    return {
      ...rest,
      url,
      headers,
    }
  },
  (err) => {
    return Promise.reject(err)
  }
)
axiosInstance.interceptors.response.use(
  (response = {}) => {
    const { status } = response
    if (status === 200) {
      try {
        return response
      } catch (error) {
        return Promise.reject(error)
      }
    }
  },
  (err) => {
    console.log('%c [ err ]-48', 'font-size:13px; background:pink; color:#bf2c9f;', err)
    if (err && err.response) {
      switch (err.response.status) {
        case 400:
          err.message = 'Request Error(400)'
          break
        case 401:
          err.message = 'Unauthorized, please login again(401)'
          break
        case 403:
          err.message = 'Access denied(403)'
          break
        case 404:
          err.message = 'Request Error(404)'
          break
        case 408:
          err.message = 'Request Error(408)'
          break
        case 500:
          err.message = 'Server Error(500)'
          break
        case 501:
          err.message = 'Service not implemented(501)'
          break
        case 502:
          err.message = 'Network Error(502)'
          break
        case 503:
          err.message = 'Service not available(503)'
          break
        case 504:
          err.message = 'Network Timeout(504)'
          break
        case 505:
          err.message = 'HTTP version not supported(505)'
          break
        default:
          err.message = `Link Error(${err.response.status})!`
      }
    } else {
      err.message = 'Failed to connect to server!'
    }
    message.error({ content: err.message })
    return Promise.reject(err)
  }
)

export default axiosInstance;