import React, { useState, useCallback, useEffect } from "react";
import { Form, Space, Button, Input, Row, Col, message, Modal } from "antd";


import * as API from "@/util/service/service";
import * as URL from "@/util/service/url";
import { LAYOUT_COL_FOUR_ONE } from '@/util/common';

import CustomTable from '@/component/CustomTable';
import DetailModal from "./detailModal";
import { EditOutlined, DeleteOutlined, UserAddOutlined, SearchOutlined } from "@ant-design/icons";

function User() {
  const [form] = Form.useForm()

  const [pageSize, setPageSize] = useState(10)
  const [pageNo, setPageNo] = useState(1)
  const [dataSource, setDataSource] = useState([])
  const [total, setTotal] = useState(0)

  const [loading, setLoading] = useState(false)

  const getList = useCallback(() => {
    const { ...field } = form.getFieldsValue()
    setLoading(true)
    const params = {
      pageSize,
      pageNo,
      ...field,
    }
    API.getBodies(URL.USER_LIST, params).then(({ status, data: res }) => {
      if (status === 200) {
        setDataSource(res.data)
        setTotal(res.total)
      }
    }).finally(() => setLoading(false))
  }, [pageSize, pageNo,])

  const handleDelete = useCallback((record) => {
    Modal.confirm({
      title: '確定刪除該用戶嗎？',
      icon: <DeleteOutlined />,
      okText: '確認',
      cancelText: '取消',
      onOk: () => {
        API.postBodies(`${URL.USER_DETAIL}/${record.id}`).then(({ status }) => {
          if (status === 200) {
            message.success('刪除成功')
            getList(false)
          }
        })
      }
    });
  }, [getList])

  const columns = [
    {
      title: '',
      dataIndex: 'sort',
      key: 'sort',
      render: (_text, _record) => {
        return (
          <div className='flex space-x-4 justify-center'>
            <Space>

              <DetailModal
                visibleType={'Update'}
                record={_record}
                onReset={() => (pageNo !== 1 ? setPageNo(1) : getList({ pageNo: 1, pageSize }))}
              >
                <Button
                  type="primary"
                  icon={<EditOutlined />}
                >
                </Button>
              </DetailModal>
              <Button
                type="primary"
                icon={<DeleteOutlined />}
                danger
                onClick={() => handleDelete(_record)}
              >
              </Button>

            </Space>
          </div>
        )
      },
    },
    {
      title: '賬戶ID',
      dataIndex: 'id',
    },
    {
      title: '用戶名稱',
      dataIndex: 'displayName',
    },
    {
      title: '賬號',
      dataIndex: 'userName',
    },
  ]


  useEffect(() => {
    getList({
      pageSize,
      pageNo,
    })
  }, [getList, pageSize, pageNo])

  return <>
    <Form
      form={form}
      onFinish={()=>  pageNo !== 1 ? setPageNo(1): getList({ pageNo: 1, pageSize })}>
      <Row gutter={[8, 0]}>
        <Col {...LAYOUT_COL_FOUR_ONE}>
          <Form.Item
            label="用戶名稱"
            name="displayName"
          >
            <Input allowClear />
          </Form.Item>
        </Col>
        <Col {...LAYOUT_COL_FOUR_ONE}>
          <Form.Item
            label="賬號"
            name="userName"
          >
            <Input allowClear />
          </Form.Item>
        </Col>
        <Col {...LAYOUT_COL_FOUR_ONE}>
          <Form.Item shouldUpdate>
            {() => (
              <Space>
                <DetailModal
                  visibleType={'Create'}
                  onReset={() => (pageNo !== 1 ? setPageNo(1) : getList({ pageNo: 1, pageSize }))}
                >
                  <Button
                    type="primary"
                    icon={<UserAddOutlined />}
                  >
                    新增
                  </Button>
                </DetailModal>
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={loading}
                  icon={<SearchOutlined />}
                >
                  查詢
                </Button>
              </Space>

            )}
          </Form.Item>
        </Col>
      </Row>
    </Form>
    <CustomTable
      columns={columns}
      dataSource={dataSource}
      pagination={{
        pageSize,
        current: pageNo,
        total,
        onShowSizeChange: setPageSize,
        onChange: setPageNo
      }}
    />
  </>
}
export default User