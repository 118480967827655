/* api abandon START */
export const SAVE_UNIFORM = "api/public/placeOrder/submit";

export const UPLOAD_PAY_SCREENSHOT = "api/public/placeOrder/submitProofOfPayment"
/* api abandon END */

/* Client Submit */
export const CLIENT_SUBMIT_ORDER = "/api/public/placeOrder/submit"

/* Internal User Login */
export const ADMIN_LOGIN = "/api/auth/login"

/* SchoolUniformOrder List */
export const ORDER_LIST = '/api/schoolUniformOrderManage/list'

/* SchoolUniformOrder UpdateColor */
export const ORDER_UPDATE = '/api/schoolUniformOrderManage/updateColor'

/* SchoolUniformOrder export */
export const ORDER_EXPORT_EXCEL = '/api/schoolUniformOrderManage/exportExcel'

/* Customer List */
export const CUSTOMER_LIST = '/api/customerManage/list'
/* Customer export */
export const CUSTOMER_EXPORT_EXCEL = '/api/customerManage/exportCustomerExcel'
/* Customer Delete {id} */
export const CUSTOMER_DELETE = '/api/customerManage/del/'
/* Customer add */
export const CUSTOMER_ADD = '/api/customerManage/add'
/* Customer edit */
export const CUSTOMER_EDIT = '/api/customerManage/edit'
/* Customer detail {id} */
export const CUSTOMER_DETAIL = '/api/customerManage/CustomerDetail/'

/* Role List */
export const ROLE_LIST = "/api/roleManage/list"
/* Add Role */
export const ADD_ROLE = "/api/roleManage/add"
/* Edit Role */
export const EDIT_ROLE = "/api/roleManage/edit"
/* Delete Role {roleCode} */ 
export const DEL_ROLE = "/api/roleManage/del/"
/* Role Detail {roleCode} */
export const ROLE_DETAIL = "/api/roleManage/detail"
/* All User List (Basic data used during creation or editing) */
export const ORDER_USER_LIST = "/api/roleManage/userList"

/* User List */
export const USER_LIST = "/api/userManage/list"
/* Add User*/
export const ADD_USER = "/api/userManage/add"
/* Edit User */
export const EDIT_USER = "/api/userManage/edit"
/* Delete User {id}*/
export const DELETE_USER = "/api/userManage/del/"
/* User Detail {id}*/
export const USER_DETAIL = "/api/userManage/detail"

/* Announcements List */
export const Announcements_LIST = "/api/announcManage/list"
/* Add Announcements*/
export const ADD_Announcements = "/api/announcManage/add"
/* Edit Announcements */
export const EDIT_Announcements = "/api/announcManage/edit"
/* Delete Announcements {id}*/
export const DELETE_Announcements = "/api/announcManage/del"
/* Announcements Detail {id}*/
export const Announcements_DETAIL = "/api/announcManage/detail"
