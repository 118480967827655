import { Form, Modal, Upload, Image, Space } from "antd";
import React, { useState } from "react";
import { PlusOutlined } from "@ant-design/icons";

import PayImage from "@/static/image2/支付二维码.png";
import { ZoomInOutlined } from "@ant-design/icons";

const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export default function UploadImage({ required }) {
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const [fileList, setFileList] = useState([]);
  const handleCancel = () => setPreviewOpen(false);
  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    );
  };
  const handleChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };

  const customRequest = (options) => {
    // const urlData = URL.createObjectURL(options.file); // blob
    options.onSuccess();
  };

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div
        style={{
          marginTop: 8,
          padding: "0 1em",
        }}
      >
        上傳截圖
      </div>
    </div>
  );

  return (
    <>
      <Space size={12}>
        <Image
          className="flex"
          alt={"支付二維碼"}
          src={PayImage}
          height={100}
          style={{ objectFit: "contain", padding: "1px 0" }}
          preview={{ mask: <ZoomInOutlined /> }}
        />
        <Form.Item
          className="flex"
          name="imageUpload"
          valuePropName="fileList"
          getValueFromEvent={normFile}
          noStyle
          rules={
            required && [
              {
                required: fileList.length === 0,
                message: "This field is required.",
              },
            ]
          }
        >
          <Upload
            name="files"
            listType="picture-card"
            fileList={fileList}
            onPreview={handlePreview}
            onChange={handleChange}
            customRequest={customRequest}
            maxCount={1}
            className="picture_pay"
            accept=".png, .jpg, .jpeg, .jp2, .gif, .tif, .dwg, .dxf"
          >
            {fileList.length >= 1 ? null : uploadButton}
          </Upload>
        </Form.Item>
      </Space>
      <Modal
        open={previewOpen}
        title={previewTitle}
        footer={null}
        onCancel={handleCancel}
      >
        <img alt="example" style={{ width: "100%" }} src={previewImage} />
      </Modal>
    </>
  );
}
