import React from "react";
import { Outlet } from "react-router-dom";

// import CWImage from "@/static/image2/cw_banner.jpg";

function Admin() {

  return <>
    <Outlet />
  </>
}
export default Admin