import React, { useState, useEffect, useRef, useCallback } from 'react'
import { Form, Input, message, DatePicker } from 'antd'

import * as API from "@/util/service/service";
import * as URL from "@/util/service/url";

import CustomModal from '@/component/CustomModal'
import dayjs from 'dayjs';
function DetailModal({ children, visibleType, onReset, record }) {

  const [form] = Form.useForm()

  const modalRef = useRef()
  const [visible, setVisible] = useState(false)



  const childrenWithProps = React.Children?.map(children, (child) =>
    React.cloneElement(child, {
      onClick: () => {
        setVisible(true)
      },
    })
  )

  const handleSave = useCallback(() => {
    return form
      .validateFields()
      .then((vals) => {

        if (visibleType === 'Create') {
          return API.postBodies(URL.ADD_Announcements, { ...vals }).then(({ status }) => {
            if (status === 200) {
              message.success('新增成功')
              onReset()
              setVisible(false)
            }
          })
        } else {
          return API.postBodies(URL.EDIT_Announcements, { ...vals }).then(({ status }) => {
            if (status === 200) {
              message.success('保存成功')
              onReset()
              setVisible(false)
            }
          })
        }
      }).catch(err => console.log(err))
  }, [form, onReset, visibleType])

  const handleOk = () => {
    return handleSave()
  }

  const handleCancel = () => {
    setVisible(false)
  }

  const getDetail = useCallback(() => {
    API.getBodies(`${URL.Announcements_DETAIL}/${record.id}`,).then(({ status, data: res }) => {
      if (status === 200) {
        console.log(record.id);
        form.setFieldValue("id",res.id)
        form.setFieldValue("content",res.content)
        form.setFieldValue("startTime",dayjs(res.startTime))
        form.setFieldValue("endTime",dayjs(res.endTime))
      }
    })
  }, [form, record])



  useEffect(() => {
    visible && visibleType === 'Update' && record && getDetail()
    return () => { form.resetFields() }
  }, [record, visible, visibleType])

  
  return (
    <>
      {childrenWithProps}
      <CustomModal
        visibleType={visibleType}
        title={`${visibleType === 'Create' ? '新增' : '修改'}通告`}
        open={visible}
        onCancel={handleCancel}
        onOk={handleOk}
      >
        <div ref={modalRef} className='w-full'></div>
        <Form
          // layout={customSize?.width <= 768 ? 'vertical' : 'horizontal'}
          form={form}
          colon={false}
        >
          <Form.Item name='id' hidden />
          <Form.Item
            label='通告内容'
            name='content'
            rules={[{ required: true, message: 'Please Input!' }]}
          >
            <Input allowClear />
          </Form.Item>
          <Form.Item
            label='開始時間'
            name='startTime'
            rules={[{ required: true, message: 'Please Input!' }]}
          >
             <DatePicker allowClear showTime/>
        
          </Form.Item>
          <Form.Item
            label='結束時間'
            name='endTime'
            rules={[{ required:  true, message: 'Please Input!' }]}
          >
             <DatePicker allowClear showTime />
          </Form.Item>

         
        </Form>
      </CustomModal>
    </>
  )
}

export default DetailModal
