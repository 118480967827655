import React, { useContext, useState, useEffect, useRef, useCallback } from 'react'
import { Form, Input, InputNumber, message, Row, Col, Select, Image } from 'antd'

import * as API from "@/util/service/service";
import * as URL from "@/util/service/url";
import { ONE_HALF, WHOLE_LINE } from '@/util/common';
import Editable from '@/component/Editable';
import Image_habit from "@/static/image3/habit.png";

import CustomModal from '@/component/CustomModal'
import { LeftCircleFilled, SortAscendingOutlined } from '@ant-design/icons';
import { set } from 'lodash';
function DetailModal({ children, visibleType, onReset, record }) {

  // TODO 高亮行 2 3 6 8 10 14 15
  const dataArrt1Constant = [
    {key: '1', attr: '肩闊', shortShirtValue1: '', shortShirtValue2: '', longShirtValue1: '', longShirtValue2: '', blazerValue1: '', blazerValue2: ''},
    {key: '2', attr: '後背闊', shortShirtValue1: '', shortShirtValue2: '', longShirtValue1: '', longShirtValue2: '', blazerValue1: '', blazerValue2: ''},
    {key: '3', attr: '後腰長', shortShirtValue1: '', shortShirtValue2: '', longShirtValue1: '', longShirtValue2: '', blazerValue1: '', blazerValue2: ''},
    {key: '4', attr: '後中長', shortShirtValue1: '', shortShirtValue2: '', longShirtValue1: '', longShirtValue2: '', blazerValue1: '', blazerValue2: ''},
    {key: '5', attr: '袖長', shortShirtValue1: '', shortShirtValue2: '', longShirtValue1: '', longShirtValue2: '', blazerValue1: '', blazerValue2: ''},
    {key: '6', attr: '袖口闊', shortShirtValue1: '', shortShirtValue2: '', longShirtValue1: '', longShirtValue2: '', blazerValue1: '', blazerValue2: ''},
    {key: '7', attr: '臂圍', shortShirtValue1: '', shortShirtValue2: '', longShirtValue1: '', longShirtValue2: '', blazerValue1: '', blazerValue2: ''},
    {key: '8', attr: '夾灣彎度', shortShirtValue1: '', shortShirtValue2: '', longShirtValue1: '', longShirtValue2: '', blazerValue1: '', blazerValue2: ''},
    {key: '9', attr: '領圍', shortShirtValue1: '', shortShirtValue2: '', longShirtValue1: '', longShirtValue2: '', blazerValue1: '', blazerValue2: ''},
    {key: '10', attr: '前胸闊', shortShirtValue1: '', shortShirtValue2: '', longShirtValue1: '', longShirtValue2: '', blazerValue1: '', blazerValue2: ''},
    {key: '11', attr: '胸圍', shortShirtValue1: '', shortShirtValue2: '', longShirtValue1: '', longShirtValue2: '', blazerValue1: '', blazerValue2: ''},
    {key: '12', attr: '腰圍', shortShirtValue1: '', shortShirtValue2: '', longShirtValue1: '', longShirtValue2: '', blazerValue1: '', blazerValue2: ''},
    {key: '13', attr: '衫腳圍', shortShirtValue1: '', shortShirtValue2: '', longShirtValue1: '', longShirtValue2: '', blazerValue1: '', blazerValue2: ''},
    {key: '14', attr: '胸高', shortShirtValue1: '', shortShirtValue2: '', longShirtValue1: '', longShirtValue2: '', blazerValue1: '', blazerValue2: ''},
    {key: '15', attr: '胸距', shortShirtValue1: '', shortShirtValue2: '', longShirtValue1: '', longShirtValue2: '', blazerValue1: '', blazerValue2: ''},
  ]
  const [dataAttr1, setDataAttr1] = useState([...dataArrt1Constant]);

  const dataAttr2Constant = [
    {key: '16', attr: '腰圍', longTrousersValue1: '', longTrousersValue2: '', westernSValue1: '', westernSValue2: ''},
    {key: '17', attr: '坐圍', longTrousersValue1: '', longTrousersValue2: '', westernSValue1: '', westernSValue2: ''},
    {key: '18', attr: '全浪', longTrousersValue1: '', longTrousersValue2: '', westernSValue1: '', westernSValue2: ''},
    {key: '19', attr: '脾圍', longTrousersValue1: '', longTrousersValue2: '', westernSValue1: '', westernSValue2: ''},
    {key: '20', attr: '膝圍', longTrousersValue1: '', longTrousersValue2: '', westernSValue1: '', westernSValue2: ''},
    {key: '21', attr: '腳闊', longTrousersValue1: '', longTrousersValue2: '', westernSValue1: '', westernSValue2: ''},
    {key: '22', attr: '褲長', longTrousersValue1: '', longTrousersValue2: '', westernSValue1: '', westernSValue2: ''},
    {key: '23', attr: '腰頭高', longTrousersValue1: '', longTrousersValue2: '', westernSValue1: '', westernSValue2: ''},
  ];
  const [dataAttr2, setDataAttr2] = useState([...dataAttr2Constant]);

  const columnsAttr2 = [
    {
      title: '',
      dataIndex: 'attr',
      key: 'attr',
      width: 100,
      // fixed: 'left',
    },
    {
      title: '長西褲',
      children: [
        {
          title: '',
          width: 100,
          dataIndex: 'longTrousersValue1',
          key: 'longTrousersValue1',
          editable: true,
        },
        {
          title: '',
          width: 100,
          dataIndex: 'longTrousersValue2',
          key: 'longTrousersValue2',
          editable: true,
        },
      ],
    },
    {
      title: '西裙',
      children: [
        {
          title: '',
          width: 100,
          dataIndex: 'westernSValue1',
          key: 'westernSValue1',
          editable: true,
        },
        {
          title: '',
          width: 100,
          dataIndex: 'westernSValue2',
          key: 'westernSValue2',
          editable: true,
        },
      ],
    },
  ];

  const columnsAttr1 = [
    {
      title: '',
      dataIndex: 'attr',
      key: 'attr',
      width: '16%',
      // fixed: 'left',
      render:(text)=><span style={{whiteSpace:'nowrap'}}>{text}</span>
    },
    {
      title: '短袖恤衫',
      children: [
        {
          title: '',
          width: '14%',
          dataIndex: 'shortShirtValue1',
          key: 'shortShirtValue1',
          editable: true,
        },
        {
          title: '',
          width: '14%',
          dataIndex: 'shortShirtValue2',
          key: 'shortShirtValue2',
          editable: true,
        },
      ],
    },
    {
      title: '長袖恤衫',
      children: [
        {
          title: '',
          width: '14%',
          dataIndex: 'longShirtValue1',
          key: 'longShirtValue1',
          editable: true,
        },
        {
          title: '',
          width: '14%',
          dataIndex: 'longShirtValue2',
          key: 'longShirtValue2',
          editable: true,
        },
      ],
    },
    {
      title: '西裝外套',
      children: [
        {
          title: '',
          width: '14%',
          dataIndex: 'blazerValue1',
          key: 'blazerValue1',
          editable: true,
        },
        {
          title: '',
          width: '14%',
          dataIndex: 'blazerValue2',
          key: 'blazerValue2',
          editable: true,
        },
      ],
    },
  ];



  const [form] = Form.useForm()
  const [customerStyleList, setCustomerStyleList] = useState([])
  const [customerAttrList, setCustomerAttrList] = useState([])

  const modalRef = useRef()
  const [visible, setVisible] = useState(false)

  const childrenWithProps = React.Children?.map(children, (child) =>
    React.cloneElement(child, {
      onClick: () => {
        setVisible(true)
      },
    })
  )

  const handleSave = useCallback(() => {
    return form
      .validateFields()
      .then((vals) => {
        let {shirt, suit, skirt, pants, ...submitData} = vals
        

        if (visibleType === 'Create') {
          let styleArr = [
            {type: '恤衫', style: (shirt && shirt.length ? shirt.join(",") : '') }, 
            {type: '西裝', style: (suit && suit.length ? suit.join(",") : '')}, 
            {type: '裙', style: (skirt && skirt.length ? skirt.join(",") : '')}, 
            {type: '褲', style: (pants && pants.length ? pants.join(",") : '')}
          ]
          let attrArr = [];
          dataAttr1.forEach((item, i) => {
            attrArr.push({type: '短袖恤衫', attr: item.attr, value1: item.shortShirtValue1, value2: item.shortShirtValue2});
            attrArr.push({type: '長袖恤衫', attr: item.attr, value1: item.longShirtValue1, value2: item.longShirtValue2});
            attrArr.push({type: '西裝外套', attr: item.attr, value1: item.blazerValue1, value2: item.blazerValue2});
          });
          dataAttr2.forEach((item, i) => {
            attrArr.push({type: '長西褲', attr: item.attr, value1: item.longTrousersValue1, value2: item.longTrousersValue2});
            attrArr.push({type: '西裙', attr: item.attr, value1: item.westernSValue1, value2: item.westernSValue2});
          });
          submitData['customerAttrDTOList'] = attrArr;
          submitData['customerStyleDTOList'] = styleArr;
          return API.postBodies(URL.CUSTOMER_ADD, { ...submitData }).then(({ status }) => {
            if (status === 200) {
              message.success('新增成功')
              onReset()
              setVisible(false)
            }
          })
        } else {
          let styleArr = [];
          customerStyleList.forEach((item, i) => {
            if (item.type === '恤衫') {
              styleArr.push({id: item.id, customerId: submitData.id, type: '恤衫', style: (shirt && shirt.length ? shirt.join(",") : '') });
            }
            if (item.type === '西裝') {
              styleArr.push({id: item.id, customerId: submitData.id, type: '西裝', style: (suit && suit.length ? suit.join(",") : '') });
            }
            if (item.type === '裙') {
              styleArr.push({id: item.id, customerId: submitData.id, type: '裙', style: (skirt && skirt.length ? skirt.join(",") : '') });
            }
            if (item.type === '褲') {
              styleArr.push({id: item.id, customerId: submitData.id, type: '褲', style: (pants && pants.length ? pants.join(",") : '') });
            }
          });
          let attrArr = [];
          customerAttrList.forEach((cItem, cI) => {
            dataAttr1.forEach((item, i) => {
              if (cItem.type === '短袖恤衫' && cItem.attr === item.attr) {
                attrArr.push({id: cItem.id, customerId: submitData.id, type: '短袖恤衫', attr: item.attr, value1: item.shortShirtValue1, value2: item.shortShirtValue2});
              }
              if (cItem.type === '長袖恤衫' && cItem.attr === item.attr) {
                attrArr.push({id: cItem.id, customerId: submitData.id, type: '長袖恤衫', attr: item.attr, value1: item.longShirtValue1, value2: item.longShirtValue2});
              }
              if (cItem.type === '西裝外套' && cItem.attr === item.attr) {
                attrArr.push({id: cItem.id, customerId: submitData.id, type: '西裝外套', attr: item.attr, value1: item.blazerValue1, value2: item.blazerValue2});
              }
            });
            dataAttr2.forEach((item, i) => {
              dataAttr2.forEach((item, i) => {
                if (cItem.type === '長西褲' && cItem.attr === item.attr) {
                  attrArr.push({id: cItem.id, customerId: submitData.id, type: '長西褲', attr: item.attr, value1: item.longTrousersValue1, value2: item.longTrousersValue2});
                }
                if (cItem.type === '西裙' && cItem.attr === item.attr) {
                  attrArr.push({id: cItem.id, customerId: submitData.id, type: '西裙', attr: item.attr, value1: item.westernSValue1, value2: item.westernSValue2});
                }
              });
            });
          });
          submitData['customerAttrDTOList'] = attrArr;
          submitData['customerStyleDTOList'] = styleArr;
          return API.postBodies(URL.CUSTOMER_EDIT, { ...submitData }).then(({ status }) => {
            if (status === 200) {
              message.success('保存成功')
              onReset()
              setVisible(false)
            }
          })
        }
      }).catch(err => console.log(err))
  }, [form, onReset, visibleType, customerAttrList, customerStyleList, dataAttr1, dataAttr2])

  const handleOk = () => {
    let result = handleSave();
    setDataAttr1([...dataArrt1Constant]);
    setDataAttr2([...dataAttr2Constant]);
    return result;
  }

  const handleCancel = () => {
    setVisible(false)
    setDataAttr1([...dataArrt1Constant]);
    setDataAttr2([...dataAttr2Constant]);
  }

  const convertToDataAttr1 = (attrList) => {
    const newDataAttr1 = [...dataAttr1];
    if (attrList) {
      attrList.forEach((item, i) => {
        const index = newDataAttr1.findIndex((nItem) => item.attr === nItem.attr);
        if (index >= 0) {
          const oleItem = newDataAttr1[index];
          let row = {};
          if (item.type === '短袖恤衫') {
            row['shortShirtValue1'] = item.value1;
            row['shortShirtValue2'] = item.value2;
          } else if (item.type === '長袖恤衫') {
            row['longShirtValue1'] = item.value1;
            row['longShirtValue2'] = item.value2;
          } else if (item.type === '西裝外套') {
            row['blazerValue1'] = item.value1;
            row['blazerValue2'] = item.value2;
          }
          newDataAttr1.splice(index, 1, {
            ...oleItem,
            ...row,
          });
        }
      });
      setDataAttr1(newDataAttr1);
    }
  }

  const convertToDataAttr2 = (attrList) => {
    const newDataAttr2 = [...dataAttr2];
    if (attrList) {
      attrList.forEach((item, i) => {
        const index = newDataAttr2.findIndex((nItem) => item.attr === nItem.attr);
        if (index >= 0) {
          const oleItem = newDataAttr2[index];
          let row = {};
          if (item.type === '長西褲') {
            row['longTrousersValue1'] = item.value1;
            row['longTrousersValue2'] = item.value2;
          } else if (item.type === '西裙') {
            row['westernSValue1'] = item.value1;
            row['westernSValue2'] = item.value2;
          }
          newDataAttr2.splice(index, 1, {
            ...oleItem,
            ...row,
          });
        }
      });
      setDataAttr2(newDataAttr2);
    }
  }

  const getDetail = useCallback(() => {
    API.getBodies(`${URL.CUSTOMER_DETAIL}${record.id}`,).then(({ status, data: res }) => {
      if (status === 200) {
        const { customerStyleDTOList, customerAttrDTOList, ...other } = res
        let tForm = {};
        customerStyleDTOList.forEach((item, i) => {
          if (item.type === '恤衫') {
            tForm['shirt'] = item.style.split(',');
          }
          if (item.type === '西裝') {
            tForm['suit'] = item.style.split(',');
          }
          if (item.type === '裙') {
            tForm['skirt'] = item.style.split(',');
          }
          if (item.type === '褲') {
            tForm['pants'] = item.style.split(',');
          }
        });
        form.setFieldsValue({
          ...other,
          ...tForm
        })
        setCustomerAttrList([...customerAttrDTOList]);
        setCustomerStyleList([...customerStyleDTOList]);
        convertToDataAttr1(customerAttrDTOList);
        convertToDataAttr2(customerAttrDTOList);
      }
    })
  }, [form, record])

  useEffect(() => {
    visible && visibleType === 'Update' && record && getDetail()
    return () => { form.resetFields() }
  }, [record, visible, visibleType])

  const shirtOptions = [{value: '修身', label: '修身'}, {value: '合身', label: '合身'}, {value: '鬆身', label: '鬆身'}, {value: '大鬆身', label: '大鬆身'}, {value: '攝衫', label: '攝衫'}, {value: '不攝衫', label: '不攝衫'}, {value: '圆腳', label: '圆腳'}, {value: '直腳', label: '直腳'}, {value: '前領低', label: '前領低'}, {value: '斜膊', label: '斜膊'}, {value: '前厚肉', label: '前厚肉'}, {value: '後厚肉', label: '後厚肉'}]
  const suitOptions = [{value: '修身', label: '修身'}, {value: '合身', label: '合身'}, {value: '鬆身', label: '鬆身'}, {value: '大鬆身', label: '大鬆身'}]
  const skirtOptions = [{value: '修身', label: '修身'}, {value: '合身', label: '合身'}, {value: '鬆身', label: '鬆身'}, {value: '大鬆身', label: '大鬆身'}, {value: '無褶', label: '無褶'}, {value: '單褶', label: '單褶'}, {value: '雙褶', label: '雙褶'}, {value: '無裡', label: '無裡'}, {value: '半裡', label: '半裡'}, {value: '全裡', label: '全裡'}]
  const pantsOptions = [{value: '大肚腩', label: '大肚腩'}, {value: '小肚腩', label: '小肚腩'}, {value: '斬褲頭', label: '斬褲頭'}, {value: '前浪短', label: '前浪短'}, {value: '橫浪闊', label: '橫浪闊'}, {value: '修身', label: '修身'}, {value: '合身', label: '合身'}, {value: '鬆身', label: '鬆身'}, {value: '大鬆身', label: '大鬆身'}, {value: '無褶', label: '無褶'}, {value: '單褶', label: '單褶'}, {value: '雙褶', label: '雙褶'}, {value: '無裡', label: '無裡'}, {value: '半裡', label: '半裡'}, {value: '全裡', label: '全裡'}]


  
  return (
    <>
      {childrenWithProps}
      <CustomModal
        visibleType={visibleType}
        title={`客戶信息`}
        open={visible}
        onCancel={handleCancel}
        onOk={handleOk}
      >
        <div ref={modalRef} className='w-full'></div>
        <Form
          // layout={customSize?.width <= 768 ? 'vertical' : 'horizontal'}
          form={form}
          colon={false}
        >
          <Form.Item name='id' hidden />
          <Row gutter={[30, 0]}>
            <Col {...ONE_HALF}>
              <Form.Item
                label='姓名'
                name='customerName'
                rules={[{ required: true, message: 'Please Input!' }]}
              >
                <Input allowClear />
              </Form.Item>
            </Col>
            <Col {...ONE_HALF}>
              <Form.Item
                label='電話'
                name='phone'
                rules={[{ required: true, message: 'Please Input!' }]}
              >
                <Input allowClear />
              </Form.Item>
            </Col>
            <Col {...ONE_HALF}>
              <Form.Item
                label='公司'
                name='company'
                rules={[{ required: true, message: 'Please Input!' }]}
              >
                <Input allowClear />
              </Form.Item>
            </Col>
            <Col {...ONE_HALF}>
              <Form.Item
                label='部門'
                name='department'
                rules={[{ required: visibleType === 'Create', message: 'Please Input!' }]}
              >
                <Input allowClear />
              </Form.Item>
            </Col>
            <Col {...ONE_HALF}>
              <Form.Item
                label='性別'
                name='gender'
                rules={[{ required: visibleType === 'Create', message: 'Please Input!' }]}
              >
                <Select
                  placeholder="Please select"
                  options={[{label: '男',value: '男'}, {label: '女',value: '女'}]}
                />
              </Form.Item>
            </Col>
            <Col {...ONE_HALF}>
              <Form.Item
                label='身高'
                name='height'
                rules={[{ required: visibleType === 'Create', message: 'Please Input!' }]}
              >
                <InputNumber allowClear />
              </Form.Item>
            </Col>
            <Col {...ONE_HALF}>
              <Form.Item
                label='體型'
                name='habit'
                rules={[{ required: visibleType === 'Create', message: 'Please Input!' }]}
              >
                <Select
                  placeholder="Please choose according to the picture"
                  options={[{label: '體型A',value: 'A'}, {label: '體型B',value: 'B'}, {label: '體型C',value: 'C'}, {label: '體型D',value: 'D'}, {label: '體型E',value: 'E'}, {label: '體型F',value: 'F'}]}
                />
              </Form.Item>
            </Col>
            <Col {...ONE_HALF}>
              <Image
                className="flex"
                src={Image_habit}
                height={150}
                style={{ objectFit: "contain", padding: "1px 0" }}
                preview={false}
              />
            </Col>
            <Col {...WHOLE_LINE}>
              <Form.Item
                label='恤衫'
                name='shirt'
                rules={[{ required: visibleType === 'Create', message: 'Please Input!' }]}
              >
                <Select
                  mode="multiple"
                  placeholder="Please select"
                  options={shirtOptions}
                />
              </Form.Item>
            </Col>
            <Col {...WHOLE_LINE}>
              <Form.Item
                label='西裝'
                name='suit'
                rules={[{ required: visibleType === 'Create', message: 'Please Input!' }]}
              >
                <Select
                  mode="multiple"
                  placeholder="Please select"
                  options={suitOptions}
                />
              </Form.Item>
            </Col>
            <Col {...WHOLE_LINE}>
              <Form.Item
                label='裙'
                name='skirt'
                rules={[{ required: visibleType === 'Create', message: 'Please Input!' }]}
              >
                <Select
                  mode="multiple"
                  placeholder="Please select"
                  options={skirtOptions}
                />
              </Form.Item>
            </Col>
            <Col {...WHOLE_LINE}>
              <Form.Item
                label='褲'
                name='pants'
                rules={[{ required: visibleType === 'Create', message: 'Please Input!' }]}
              >
                <Select
                  mode="multiple"
                  placeholder="Please select"
                  options={pantsOptions}
                />
              </Form.Item>
            </Col>
            <Col {...WHOLE_LINE}>
              <Editable
                defaultColumns={columnsAttr1}
                dataSource={dataAttr1}
                setDataSource={setDataAttr1}
                highlightRow={['2', '3', '6', '8', '10', '14', '15']}
              />
              <br/>
            </Col>
            <Col {...WHOLE_LINE}>
              <Editable
                defaultColumns={columnsAttr2}
                dataSource={dataAttr2}
                setDataSource={setDataAttr2}
                highlightRow={[]}
              />
            </Col>
          </Row>
        </Form>
      </CustomModal>
    </>
  )
}

export default DetailModal
