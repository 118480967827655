import React, { useState, useEffect, useRef, useCallback } from 'react'
import { Form, Input, message } from 'antd'

import * as API from "@/util/service/service";
import * as URL from "@/util/service/url";

import CustomModal from '@/component/CustomModal'
function DetailModal({ children, visibleType, onReset, record }) {

  const [form] = Form.useForm()

  const modalRef = useRef()
  const [visible, setVisible] = useState(false)

  // const [orginRole, setOrginRole] = useState([])
  // const getOpts = useCallback(() => {
  //   API.getBodies(URL.ORDER_USER_LIST).then(({ status, data: res }) => {
  //     if (status === 200) {
  //       setOrginRole(res.map(m => ({
  //         key: m.key,
  //         title: m.val,
  //       })))
  //     }
  //   })
  // }, [])

  const childrenWithProps = React.Children?.map(children, (child) =>
    React.cloneElement(child, {
      onClick: () => {
        setVisible(true)
      },
    })
  )

  const handleSave = useCallback(() => {
    return form
      .validateFields()
      .then((vals) => {

        if (visibleType === 'Create') {
          return API.postBodies(URL.ADD_USER, { ...vals }).then(({ status }) => {
            if (status === 200) {
              message.success('新增成功')
              onReset()
              setVisible(false)
            }
          })
        } else {
          return API.postBodies(URL.EDIT_USER, { ...vals }).then(({ status }) => {
            if (status === 200) {
              message.success('保存成功')
              onReset()
              setVisible(false)
            }
          })
        }
      }).catch(err => console.log(err))
  }, [form, onReset, visibleType])

  const handleOk = () => {
    return handleSave()
  }

  const handleCancel = () => {
    setVisible(false)
  }

  const getDetail = useCallback(() => {
    API.getBodies(`${URL.USER_DETAIL}/${record.id}`,).then(({ status, data: res }) => {
      if (status === 200) {
        const { roleList, ...other } = res
        form.setFieldsValue({
          ...other,
          roleList: roleList.map(el => el.key)
        })
      }
    })
  }, [form, record])

  // useEffect(() => {
  //   getOpts()
  // }, [])

  useEffect(() => {
    visible && visibleType === 'Update' && record && getDetail()
    return () => { form.resetFields() }
  }, [record, visible, visibleType])


  return (
    <>
      {childrenWithProps}
      <CustomModal
        visibleType={visibleType}
        title={`${visibleType === 'Create' ? '新增' : '修改'}用戶`}
        open={visible}
        onCancel={handleCancel}
        onOk={handleOk}
      >
        <div ref={modalRef} className='w-full'></div>
        <Form
          // layout={customSize?.width <= 768 ? 'vertical' : 'horizontal'}
          form={form}
          colon={false}
        >
          <Form.Item name='id' hidden />
          <Form.Item
            label='用戶名稱'
            name='displayName'
            rules={[{ required: true, message: 'Please Input!' }]}
          >
            <Input allowClear />
          </Form.Item>
          <Form.Item
            label='賬號'
            name='userName'
            rules={[{ required: true, message: 'Please Input!' }]}
          >
            <Input allowClear />
          </Form.Item>
          <Form.Item
            label='密碼'
            name='password'
            rules={[{ required: visibleType === 'Create', message: 'Please Input!' }]}
          >
            <Input.Password allowClear />
          </Form.Item>

          {/* <Form.Item
            label='權限列表'
            name='roleList'
            rules={[{ required: true, message: 'Please select!' }]}
          >
            <FormTransfer
              dataSource={orginUser}
            />
          </Form.Item> */}
        </Form>
      </CustomModal>
    </>
  )
}

export default DetailModal
