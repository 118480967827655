import { Checkbox } from 'antd'
import React from 'react'

const RadioCheckboxGroup = ({ value, onChange, options = [], className }) => {
    console.log('%c [ value ]-5', 'font-size:13px; background:pink; color:#bf2c9f;', value)
    const handleOnChange = (e) => {
        console.log('%c [ e ]-6', 'font-size:13px; background:pink; color:#bf2c9f;', e)
        let newValue = ''
        console.log('%c [ value ]-10', 'font-size:13px; background:pink; color:#bf2c9f;', value)
        console.log('%c [ e.target.value ]-11', 'font-size:13px; background:pink; color:#bf2c9f;', e.target.value)
        if (value === e.target.value) {
            console.log('%c [ e.target.checked ]-13', 'font-size:13px; background:pink; color:#bf2c9f;', e.target.checked)
            if (e.target.checked) {
                newValue = e.target.value
            } else {
                newValue = ''
            }
        } else {
            newValue = e.target.value
        }
        onChange(newValue)
    }
    return (
        <Checkbox.Group value={[value]} className={`contents flex-1 ${className}`}>
            <div className='flex flex-col items-start justify-normal flex-1'>
                {Array.isArray(options) &&
                    options.length > 0 &&
                    options.map((m, index) => {
                        return (
                            <div className={`w-full flex flex-col ${m?.className}`} key={`${m?.value}_${index}`}>
                                <Checkbox value={m?.value} onChange={handleOnChange} className='w-full'>
                                    <span className='text-primary flex w-full text-left'>{m?.label}</span>
                                </Checkbox>
                                {typeof m?.renderOther == 'function' && m?.renderOther(m)}
                            </div>
                        )
                    })}
            </div>
        </Checkbox.Group>
    )
}

export default RadioCheckboxGroup