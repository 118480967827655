import React, { useState, useEffect } from 'react'
import { Transfer } from 'antd'
import './index.less'

/**
 * @property {Array} dataSource the datasource for transfer
 * @property {Array} value current targetSelect value for this transfer
 * @property {Function} onChange the targetSelect change function
 * @property {Function} render custom render the item
 * @returns
 */
export default function FormTransfer({
  dataSource,
  value,
  onChange,
  render = (item) => <span className='whitespace-normal'>{item.title}</span>,
  ...rest
}) {
  const [targetKeys, setTargetKeys] = useState([])
  const [selectedKeys, setSelectedKeys] = useState([])

  const onDataChange = (nextTargetKeys) => {
    setTargetKeys(nextTargetKeys.filter((f) => f))
    onChange(nextTargetKeys.filter((f) => f))
  }

  const onSelectChange = (sourceSelectedKeys, targetSelectedKeys) => {
    setSelectedKeys([...sourceSelectedKeys, ...targetSelectedKeys])
  }

  useEffect(() => {
    if (Array.isArray(value)) {
      setTargetKeys(value)
    }
  }, [value])

  return (
    <Transfer
      className={`w-full flex flex-wrap  flex-1 justify-between items-center mb-4 custom_form_transfer ${rest?.size}`}
      listStyle={{ flexGrow: 1 }}
      dataSource={dataSource}
      titles={['Registered Data Items', 'Granted APIs']}
      targetKeys={targetKeys}
      selectedKeys={selectedKeys}
      onChange={onDataChange}
      onSelectChange={onSelectChange}
      render={render}
      {...rest}
    />
  )
}
