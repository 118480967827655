import { Image } from "antd";
import React, { useState } from "react";
import { ZoomInOutlined } from "@ant-design/icons";

export default function ImageGroup({ title, img, imgData = [] }) {
  const [visible, setVisible] = useState(false);

  const clickImage = () => {
    setVisible(true);
  };

  const visibleChange = (visible) => {
    setVisible(visible);
  };

  return (
    <>
      <Image
        preview={{
          visible: false,
          mask: <ZoomInOutlined />,
        }}
        // height={148}
        src={img}
        title={title}
        onClick={clickImage}
        style={{ objectFit: "cover", padding: "1px 0",height: "270px"}}
      />
      <div
        style={{
          display: "none",
        }}
      >
        <Image.PreviewGroup
          preview={{
            visible,
            onVisibleChange: visibleChange,
          }}
        >
          {imgData.map((item, index) => (
            <Image key={index} title={item.title} src={item.img} />
          ))}
        </Image.PreviewGroup>
      </div>
    </>
  );
}
