import React, { useContext, useEffect, useRef, useState } from 'react';
import './index.css';
import { Form, Input, Table } from 'antd';

export default function CustomEditable({defaultColumns, dataSource, setDataSource, highlightRow}){

  const EditableContext = React.createContext(null);
  const EditableRow = ({ index, ...props }) => {
    const [form] = Form.useForm();
    // props['className'] = props.className + ', '
    return (
      <Form form={form} component={false}>
        <EditableContext.Provider value={form}>
          <tr {...props} />
        </EditableContext.Provider>
      </Form>
    );
  };
  const EditableCell = ({
    title,
    editable,
    children,
    dataIndex,
    record,
    handleSave,
    ...restProps
  }) => {
    const [editing, setEditing] = useState(false);
    const inputRef = useRef(null);
    const form = useContext(EditableContext);
    useEffect(() => {
      if (editing) {
        inputRef.current.focus();
      }
    }, [editing]);
    const toggleEdit = () => {
      setEditing(!editing);
      form.setFieldsValue({
        [dataIndex]: record[dataIndex],
      });
    };
    const save = async () => {
      try {
        const values = await form.validateFields();
        toggleEdit();
        handleSave({
          ...record,
          ...values,
        });
      } catch (errInfo) {
        console.log('Save failed:', errInfo);
      }
    };
    let childNode = children;
    if (editable) {
      childNode = editing ? (
        <Form.Item
          style={{
            margin: 0,
          }}
          name={dataIndex}
          // rules={[
          //   {
          //     required: true,
          //     message: `${title} is required.`,
          //   },
          // ]}
        >
          <Input ref={inputRef} onPressEnter={save} onBlur={save} />
        </Form.Item>
      ) : (
        <div
          className="editable-cell-value-wrap"
          style={{
            paddingRight: 24,
          }}
          onClick={toggleEdit}
        >
          {children}
        </div>
      );
    }
    return <td {...restProps}>{childNode}</td>;
  };
  // const Editable = () => {
    // const [dataSource, setDataSource] = useState([
    //   {
    //     key: '0',
    //     name: 'Edward King 0',
    //     age: '32',
    //     address: 'London, Park Lane no. 0',
    //   },
    //   {
    //     key: '1',
    //     name: 'Edward King 1',
    //     age: '32',
    //     address: 'London, Park Lane no. 1',
    //   },
    // ]);
    // const [dataSource, setDataSource] = useState(dataSourceSource);
    const handleSave = (row) => {
      const newData = [...dataSource];
      const index = newData.findIndex((item) => row.key === item.key);
      const item = newData[index];
      newData.splice(index, 1, {
        ...item,
        ...row,
      });
      setDataSource(newData);
    };
    const components = {
      body: {
        row: EditableRow,
        cell: EditableCell,
      },
    };
    const columns = defaultColumns.map((col) => {
      if (!col.editable && !col.children) {
        return col;
      }
      if (col.children) {
        let childrenList = col.children.map((c) => {
          if (!c.editable) {
            return c;
          }
          return {
            ...c,
            onCell: (record) => ({
              record,
              editable: c.editable,
              dataIndex: c.dataIndex,
              title: c.title,
              handleSave,
            }),
          };
        });
        return {
          ...col,
          children: childrenList,
        }
      } else if (col.editable) {
        return {
          ...col,
          onCell: (record) => ({
            record,
            editable: col.editable,
            dataIndex: col.dataIndex,
            title: col.title,
            handleSave,
          }),
        };
      } else {
        return col;
      }
    });
    return (
      <div>
        <Table
          components={components}
          rowClassName={(record) => {
           return highlightRow.includes(record?.key) ? 'editable-row highlight':'editable-row'
          }}
          bordered
          dataSource={dataSource}
          columns={columns}
          rowKey={(dataSource) => dataSource.key}
          pagination={false}
        />
      </div>
    );
  // };

// export default Editable;

}