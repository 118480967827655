import React from "react";
import { Card, Form, Button, Input } from "antd";
import { useNavigate } from "react-router-dom";

import * as API from "@/util/service/service";
import * as URL from "@/util/service/url";

import {
  ProductContain,
  CardTitle,
  DescText,
  ProductText,
} from "@/component/common/wrapStyle";

function Login() {
  const navigate = useNavigate();

  const [form] = Form.useForm()

  const onFinish = (values) => {
    try{
    API.postBodies(URL.ADMIN_LOGIN, values)
      .then((res) => {
        console.log('%c [ res ]-20', 'font-size:13px; background:pink; color:#bf2c9f;', res)
        if (res.status === 200) {
          const setToken = res.data?.type + res.data?.token
          const UserData = JSON.stringify(res.data)

          sessionStorage.setItem('token', setToken)
          sessionStorage.setItem('UserData', UserData)
          sessionStorage.setItem('expiration', res.data.expiration)
          navigate('/admin')
        }
      })
    }
    catch(e){
      console.log('%c [ e ]-45', 'font-size:13px; background:pink; color:#bf2c9f;', e)
    }
  };
  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };
  return <ProductContain> <Card
    title={
      <CardTitle>
        <ProductText>Administrator Login</ProductText>
        <DescText style={{ fontWeight: "normal" }}>管理员登陆</DescText>
      </CardTitle>
    }
    bordered={false}
    style={{ 'box-shadow': 'none' }}
  >
    <Form
      form={form}
      labelCol={{
        span: 8,
      }}
      wrapperCol={{
        span: 16,
      }}
      style={{
        maxWidth: 600,
      }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
      initialValues={{

      }}
    >
      <Form.Item
        label="Username"
        name="username"
        rules={[
          {
            required: true,
            message: 'Please input your username!',
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Password"
        name="password"
        rules={[
          {
            required: true,
            message: 'Please input your password!',
          },
        ]}
      >
        <Input.Password />
      </Form.Item>

      {/* <Form.Item
      name="remember"
      valuePropName="checked"
      wrapperCol={{
        offset: 8,
        span: 16,
      }}
    >
      <Checkbox>Remember me</Checkbox>
    </Form.Item> */}

      <Form.Item
        wrapperCol={{
          offset: 8,
          span: 16,
        }}
      >
        <Button type="primary" htmlType="submit">
          Submit
        </Button>
      </Form.Item>
    </Form>
  </Card>
  </ProductContain>
}
export default Login