import React, { useState, useCallback, useEffect } from "react";
import { Form, DatePicker, Input, Button, Select, Row, Col, Space, message, Modal} from "antd";

import * as API from "@/util/service/service";
import * as URL from "@/util/service/url";
import { LAYOUT_COL_FOUR_ONE, downloadFile } from '@/util/common';

import CustomTable from '@/component/CustomTable';
import dayjs from "dayjs";
import DetailModal from "./detailModal";
import OrderListModal from "./orderList";
import { ExportOutlined, SearchOutlined, EditOutlined, InfoCircleOutlined, DeleteOutlined, UserAddOutlined } from "@ant-design/icons";

function Customer() {
  const [form] = Form.useForm()

  const [pageSize, setPageSize] = useState(10)
  const [pageNo, setPageNo] = useState(1)
  const [dataSource, setDataSource] = useState([])
  const [total, setTotal] = useState(0)

  const [loading, setLoading] = useState(false)

  const columns = [
    {
      title: '',
      dataIndex: 'sort',
      key: 'id',
      render: (_text, _record) => {
        return (
          <div className='flex space-x-4 justify-center'>
            <Space>

              <DetailModal
                visibleType={'Update'}
                record={_record}
                onReset={() => (pageNo !== 1 ? setPageNo(1) : getList({ pageNo: 1, pageSize }))}
              >
                <Button
                  type="primary"
                  icon={<InfoCircleOutlined />}
                >
                </Button>
              </DetailModal>
              <Button
                type="primary"
                icon={<DeleteOutlined />}
                danger
                onClick={() => handleDelete(_record)}
              >
              </Button>

            </Space>
          </div>
        )
      },
    },
    {
      title: '客戶ID',
      dataIndex: 'id',
    },
    {
      title: '姓名',
      dataIndex: 'customerName',
    },
    {
      title: '電話',
      dataIndex: 'phone',
    },
    {
      title: '公司',
      dataIndex: 'company',
    },
    {
      title: '部門',
      dataIndex: 'department',
    },
    {
      title: '性別',
      dataIndex: 'gender',
    },
    {
      title: '訂單數量',
      dataIndex: 'orderCount',
      key: 'id',
      render: (_text, _record) => {
        return (
          <div className='flex space-x-4 justify-center'>
            <Space>

              <OrderListModal
                visibleType={'Enquire'}
                record={_record}
              >
                <Button>
                  {_record.orderCount}
                </Button>
              </OrderListModal>
            </Space>
          </div>
        )
      },
    },
    {
      title: '日期',
      dataIndex: 'updatedAt',
    },
  ]

  const getList = useCallback(() => {
    const { DateTime, ...field } = form.getFieldsValue()
    setLoading(true)
    const params = {
      pageSize,
      pageNo,
      ...field,
    }
    DateTime?.[0] && (params.startTime = dayjs(DateTime[0]).startOf('day').format('YYYY-MM-DD HH:mm:ss'))
    DateTime?.[0] && (params.endTime = dayjs(DateTime[1]).endOf('day').format('YYYY-MM-DD HH:mm:ss'))

    API.getBodies(URL.CUSTOMER_LIST, params).then(({ status, data: res }) => {
      if (status === 200) {
        setDataSource(res.data)
        setTotal(res.total)
      }
    }).finally(() => setLoading(false))
  }, [pageSize, pageNo])

  const handleDelete = useCallback((record) => {
    Modal.confirm({
      title: '確定刪除該客戶嗎？',
      icon: <DeleteOutlined />,
      okText: '確認',
      cancelText: '取消',
      onOk: () => {
        API.postBodies(`${URL.CUSTOMER_DELETE}${record.id}`).then(({ status }) => {
          if (status === 200) {
            message.success('刪除成功')
            getList(false)
          }
        })
      }
    });
  }, [getList])

  useEffect(() => {
    getList({
      pageSize,
      pageNo,
    })
  }, [getList, pageSize, pageNo])

  const handleOnExport = useCallback(() => {
    return form
      .validateFields()
      .then(({ DateTime, ...vals }) => {
        const params = {
          ...vals,
        }
        DateTime?.[0] && (params.startTime = dayjs(DateTime[0]).startOf('day').format('YYYY-MM-DD HH:mm:ss'))
        DateTime?.[0] && (params.endTime = dayjs(DateTime[1]).endOf('day').format('YYYY-MM-DD HH:mm:ss'))

        return API.postBodies(URL.CUSTOMER_EXPORT_EXCEL, params, {
          responseType: 'blob'
        }).then((res) => {
          if (res.status === 200) {
            message.success('導出成功')
            downloadFile(res)
          }
        })
      }).catch(err => console.log(err))
  }, [])

  return <>
    <Form
      form={form}
      onFinish={()=>  pageNo !== 1 ? setPageNo(1): getList({ pageNo: 1, pageSize })}>
      <Row gutter={[8, 0]}>
        <Col {...LAYOUT_COL_FOUR_ONE}>
          <Form.Item
            label="客戶姓名"
            name="customerName"
          >
            <Input allowClear />
          </Form.Item>
        </Col>
        <Col {...LAYOUT_COL_FOUR_ONE}>
          <Form.Item
            label="電話"
            name="phone"
          >
            <Input allowClear />
          </Form.Item>
        </Col>
        <Col {...LAYOUT_COL_FOUR_ONE}>
          <Form.Item
            label="性別"
            name="gender"
          >
            <Select
              allowClear
              placeholder="Please select"
              options={[{label: '男',value: '男'}, {label: '女',value: '女'}]}
            />
          </Form.Item>
        </Col>
        <Col {...LAYOUT_COL_FOUR_ONE}>
          <Form.Item
            label="公司"
            name="company"
          >
            <Input allowClear />
          </Form.Item>
        </Col>
        <Col {...LAYOUT_COL_FOUR_ONE}>
          <Form.Item
            label="職位"
            name="department"
          >
            <Input allowClear />
          </Form.Item>
        </Col>
        <Col {...LAYOUT_COL_FOUR_ONE}>
          <Form.Item
            label="更新時間"
            name="DateTime"
          >
            <DatePicker.RangePicker allowClear className="w-full" />
          </Form.Item>
        </Col>
        <Col {...LAYOUT_COL_FOUR_ONE}>
          <Form.Item shouldUpdate>
            {() => (
              <Space>
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={loading}
                  icon={<SearchOutlined />}
                >
                  查詢
                </Button>

                <Button
                  type="primary"
                  icon={<ExportOutlined />}
                  onClick={handleOnExport}
                >
                  導出
                </Button>
              </Space>
            )}
          </Form.Item>
        </Col>
        <Col {...LAYOUT_COL_FOUR_ONE}>
          <Space style={{display:'flex',width:'100%',justifyContent:'flex-end',alignItems:'center'}}>
            <DetailModal
              visibleType={'Create'}
              onReset={() => (pageNo !== 1 ? setPageNo(1) : getList({ pageNo: 1, pageSize }))}
            >
              <Button 
                type="primary"
                icon={<UserAddOutlined />}
              >
                新增
              </Button>
            </DetailModal>
          </Space>
        </Col>
      </Row>
    </Form>
    <CustomTable
      columns={columns}
      dataSource={dataSource}
      pagination={{
        pageSize,
        current: pageNo,
        total,
        onShowSizeChange: setPageSize,
        onChange: setPageNo
      }}
      
    />
  </>
}
export default Customer